import { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNewHttpClient } from 'hooks';
import { CUSTOMER_SUPPORT_FLOW_API } from 'configs/api/customerSupportFlow';
import { IFlow, IFlowItemToUpdateInfo, IUpdateFlowItemsStatusPayload } from 'types/api';
import { EStatus, TEmptyFunction } from 'types/common';
import { processOrderFlow } from 'pages/CustomerSupportFlow/CustomerSupportFlowDetails/helper';
import { INodeTrunk } from 'pages/CustomerSupportFlow/CustomerSupportFlowDetails/types';

interface ICustomerSupportFlowDetailsContextData {
	items: IFlowItemToUpdateInfo[];
	isEditing: boolean;
	isLoading: boolean;
	fetchError: any;
	formattedFlow?: INodeTrunk;

	updateItemsToEdit: (path: string, slug: string, status: EStatus) => void;
	handleStartEditing: TEmptyFunction;
	handleStopEditing: TEmptyFunction;
	handleUpdateFlow: TEmptyFunction;
}

export const CustomerSupportFlowDetailsContext = createContext<ICustomerSupportFlowDetailsContextData>(
	{} as ICustomerSupportFlowDetailsContextData
);

export const CustomerSupportFlowDetailsProvider: FC<PropsWithChildren> = ({ children }) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [itemsToEdit, setItemsToEdit] = useState<ICustomerSupportFlowDetailsContextData['items']>([]);
	const [formattedFlow, setFormattedFlow] = useState<INodeTrunk>();

	const { customerSupportFlowIdentifier = '' } = useParams();

	// ! http client
	const fetchHttpClient = useNewHttpClient<IFlow>();
	const updateFlowItemsStatusHttpClient = useNewHttpClient();

	// ! handlers
	const fetchFlowData = () => {
		fetchHttpClient.request({
			requestConfig: CUSTOMER_SUPPORT_FLOW_API.getFlowDetails(customerSupportFlowIdentifier),
			successCallback: (response) => {
				setFormattedFlow(processOrderFlow(response));
			},
		});
	};

	const updateItemsToEdit = (path: string, slug: string, status: EStatus) => {
		setItemsToEdit((prev) => {
			const existingIndex = prev.findIndex((item) => item.path === path && item.slug === slug);

			// removing
			if (existingIndex > -1) {
				return prev.filter((item) => item.path !== path && item.slug !== slug);
			}

			// adding
			return [...prev, { path, slug, status }];
		});
	};

	const handleStartEditing = () => {
		setIsEditing(true);
	};

	const handleStopEditing = () => {
		setItemsToEdit([]);
		setIsEditing(false);
	};

	const handleUpdateFlow = () => {
		const payload: IUpdateFlowItemsStatusPayload = {
			data: itemsToEdit,
		};

		updateFlowItemsStatusHttpClient.request({
			requestConfig: CUSTOMER_SUPPORT_FLOW_API.changeFlowItemsStatus(customerSupportFlowIdentifier, payload),
			successCallback: () => {
				handleStopEditing();
				fetchFlowData();
			},
		});
	};

	// ! effects
	useEffect(() => {
		fetchFlowData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! data
	const data: ICustomerSupportFlowDetailsContextData = {
		isEditing,
		isLoading: fetchHttpClient.isLoading,
		fetchError: fetchHttpClient.error,
		items: itemsToEdit,
		formattedFlow,

		updateItemsToEdit,
		handleStartEditing,
		handleStopEditing,
		handleUpdateFlow,
	};

	return (
		<CustomerSupportFlowDetailsContext.Provider value={data}>{children}</CustomerSupportFlowDetailsContext.Provider>
	);
};
