import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { CUSTOMER_SUPPORT_REQUEST_TAG_COLOR_CONFIG } from './configs';
import { ICustomerSupportRequestStatusTagProps } from './types';
import LoadingTag from 'components/LoadingTag';
import { Tag } from 'antd';

const CustomerSupportRequestStatusTag: FC<ICustomerSupportRequestStatusTagProps> = ({ status }) => {
	const { t: tCustomerSupportRequests } = useTranslation('customer-support-requests');

	if (!status) return <LoadingTag />;

	return (
		<Tag
			color={CUSTOMER_SUPPORT_REQUEST_TAG_COLOR_CONFIG[status]}
			style={{
				...DEFAULT_TAG_STYLES,
				textTransform: 'uppercase',
			}}
		>
			{tCustomerSupportRequests(`status.${status}`)}
		</Tag>
	);
};

export default memo(CustomerSupportRequestStatusTag);
