import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth, useBranch, useConfirmModal, useNewHttpClient, useVendor } from 'hooks';
import { VENDOR_BRANCH_API, VENDOR_BRANCH_IMAGE_BANNER_API } from 'configs/api';
import { DEFAULT_DESCRIPTIONS_PROPS, DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { BRANCH_IMG_DELETE_CONFIG, CARD_IMG_BODY_STYLES, DeleteImgBtn } from './configs';
import { IBranch } from 'types/api';
import { EChoiceOption, EDeliveryMode, EImgFileSize } from 'types/common';
import { TBranchImg } from './types';
import AddressLinkButton from 'components/AddressLinkButton';
import ImageBox from 'components/ImageBox';
import ImgEditModal from 'components/ImgEditModal';
import RedirectToOrdersListButton from 'components/RedirectToOrdersListButton';
import { DeliveryModeTag } from 'components/Tags/DeliveryModeTag/DeliveryModeTag';
import { TextSplitter } from 'components/TextSplitter';
import ErrorPage from 'pages/ErrorPage';
import styles from '../../BranchDetails.module.css';
import BranchStatusTag from '../../SharedComponents/BranchStatusTag';
import { App, Button, Card, Col, Descriptions, Row, Space, Switch } from 'antd';
import { ClockCircleOutlined, EditOutlined, EnvironmentOutlined } from '@ant-design/icons';

const InfoTabContent: FC = () => {
	const { vendorId } = useVendor();
	const {
		branchId,
		data: branchData,
		error: branchDataError,
		isLoading: branchDataLoading,
		fetchData,
		setData,
	} = useBranch();

	const navigate = useNavigate();
	const { hasPermission } = useAuth();
	const { openConfirmModal } = useConfirmModal();
	const { message } = App.useApp();
	const { t: tCommon } = useTranslation('common');
	const { t: tVendorBranch } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.branches' });

	const [editingBranchImg, setEditingBranchImg] = useState<boolean>(false);
	const [editingBranchBanner, setEditingBranchBanner] = useState<boolean>(false);

	// ! http clients
	const deleteImagesHttpClient = useNewHttpClient();
	const allowCashPaymentHttpClient = useNewHttpClient<IBranch>();

	// ! handlers
	const handleNavigateToEdit = () => navigate('../edit');

	const handleOpenBranchImgEditModal = () => setEditingBranchImg(true);

	const handleOpenBranchBannerEditModal = () => setEditingBranchBanner(true);

	const handleCloseBranchImgOrBannerEditModal = (refresh: boolean) => {
		setEditingBranchImg(false);
		setEditingBranchBanner(false);
		if (refresh) fetchData();
	};

	const handleDeleteBranchImages = (type: TBranchImg) => {
		return deleteImagesHttpClient.request({
			requestConfig: BRANCH_IMG_DELETE_CONFIG[type](vendorId, branchId),
			successCallback: () => {
				message.success(
					tVendorBranch('branch_details.tabs.info.messages.delete_img.success', { id: branchId, type }),
					3
				);
				fetchData();
			},
		});
	};

	const onDeleteBranchImages = (type: TBranchImg) => {
		openConfirmModal({
			title: tVendorBranch('branch_details.tabs.info.messages.delete_img.alert', { type }),
			content: tVendorBranch('branch_details.tabs.info.messages.delete_img.note', { type }),
			onOk: () => handleDeleteBranchImages(type),
		});
	};

	const handleAllowCashPaymentChange = (checked: boolean, branchData: IBranch) => {
		return allowCashPaymentHttpClient.request({
			requestConfig: VENDOR_BRANCH_API.update(vendorId, branchData.id, {
				allow_cash_payment: checked ? EChoiceOption.YES : EChoiceOption.NO,
			}),
			successCallback: (response) => {
				setData(response);
			},
		});
	};

	// ! render
	if (branchDataError) {
		return (
			<ErrorPage
				status={branchDataError.status}
				title={branchDataError.status}
				subTitle={branchDataError.data?.message}
			/>
		);
	}

	if (!branchData || (!branchData && branchDataLoading)) {
		return null;
	}

	const { delivery, info, images, location, schedule, contact_info, area } = branchData;

	const followUpNumber = contact_info.contact_number
		? `(${contact_info.phone_country_code}) ${contact_info.contact_number}`
		: DEFAULT_EMPTY_VALUE_PLACEHOLDER;

	const canUploadImage = hasPermission(APP_PERMISSIONS.vendor.store.upload_image);
	const canUploadBanner = hasPermission(APP_PERMISSIONS.vendor.store.upload_banner_image);

	return (
		<>
			{/* BRANCH MAIN INFO */}
			<Space
				size={16}
				direction='vertical'
			>
				<Card>
					{/* DESCRIPTIONS */}
					<Row
						gutter={16}
						wrap={false}
					>
						{/* MAIN INFO */}
						<Col flex='auto'>
							<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
								<Descriptions.Item label={tVendorBranch('shared.branch_status')}>
									<BranchStatusTag
										key='branch_status_tag'
										status={branchData?.info.status ?? null}
									/>
								</Descriptions.Item>
								<Descriptions.Item label={tVendorBranch('shared.name')}>
									<Space split={<TextSplitter />}>
										{info.name}
										{info.name_ar || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
									</Space>
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.display_name')}>
									<Space split={<TextSplitter />}>
										{info.display_name}
										{info.display_name_ar || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
									</Space>
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.contact_number')}>
									{followUpNumber}
								</Descriptions.Item>

								<Descriptions.Item
									label={tVendorBranch('shared.description')}
									className={styles.branch_description}
								>
									{info.description || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>

								<Descriptions.Item
									label={tVendorBranch('shared.description_ar')}
									className={styles.branch_description}
								>
									{info.description_ar || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>
								<Descriptions.Item label={tVendorBranch('shared.area')}>
									{area?.name || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.address')}>
									<AddressLinkButton
										address={location.address}
										icon={<EnvironmentOutlined />}
										addressLatLng={location}
									/>
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('form.allow_cash_payment.label')}>
									<Switch
										size='small'
										checkedChildren={tCommon('on_off_switch.yes')}
										unCheckedChildren={tCommon('on_off_switch.no')}
										checked={delivery.allow_cash_payment === EChoiceOption.YES}
										disabled={!hasPermission(APP_PERMISSIONS.vendor.store.update)}
										onChange={(checked) => handleAllowCashPaymentChange(checked, branchData)}
									/>
								</Descriptions.Item>
							</Descriptions>

							<RedirectToOrdersListButton branchId={branchId}>
								{tVendorBranch('actions.redirect_to_orders')}
							</RedirectToOrdersListButton>
						</Col>

						<Col flex='auto'>
							<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
								<Descriptions.Item label={tVendorBranch('shared.opening_hours')}>
									<Space size={6}>
										{schedule.opening_hours?.length
											? schedule.opening_hours
											: DEFAULT_EMPTY_VALUE_PLACEHOLDER}
										<ClockCircleOutlined />
									</Space>
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.delivery_mode')}>
									<DeliveryModeTag deliveryMode={info.delivery_mode} />
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.scheduled_order_support')}>
									{info.scheduled_order_support
										? tVendorBranch(`scheduled_order_support.${info.scheduled_order_support}`)
										: DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.preparation_mode')}>
									{info.preparation_mode
										? tVendorBranch(`preparation_mode.${info.preparation_mode}`)
										: DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.preparation_time')}>
									{info.preparation_time ?? DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>

								{info.delivery_mode === EDeliveryMode.PLATFORM && (
									<Descriptions.Item label={tVendorBranch('shared.assignment_mode')}>
										{info.assignment_mode
											? tCommon(`assignment_mode.labels.${info.assignment_mode}`)
											: DEFAULT_EMPTY_VALUE_PLACEHOLDER}
									</Descriptions.Item>
								)}

								<Descriptions.Item label={tVendorBranch('shared.range_order_delivery_time')}>
									{delivery?.order_delivery_time || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.delivery_fee')}>
									{delivery?.fee ?? DEFAULT_EMPTY_VALUE_PLACEHOLDER}
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.in_progress_limit')}>
									{delivery.in_progress_limit}
								</Descriptions.Item>

								<Descriptions.Item label={tVendorBranch('shared.driver_attendance_radius')}>
									{delivery.driver_attendance_radius}
								</Descriptions.Item>
							</Descriptions>
						</Col>

						{/* EDIT BUTTON */}
						<Col flex='none'>
							<Button
								icon={<EditOutlined />}
								onClick={handleNavigateToEdit}
								disabled={!hasPermission(APP_PERMISSIONS.vendor.store.update)}
							>
								{tCommon('action_buttons.edit')}
							</Button>
						</Col>
					</Row>
				</Card>

				<Row
					gutter={16}
					wrap={false}
				>
					<Col flex='auto'>
						<Card
							size='small'
							styles={{ body: CARD_IMG_BODY_STYLES }}
							title={tVendorBranch('branch_details.tabs.info.image')}
							extra={
								<DeleteImgBtn
									type='image'
									onDelete={onDeleteBranchImages}
									disabled={!canUploadImage}
								/>
							}
						>
							<ImageBox
								buttonDisabled={!canUploadImage}
								bordered={false}
								image={images.logo}
								onAddEditBtnClick={handleOpenBranchImgEditModal}
							/>
						</Card>
					</Col>

					<Col flex='auto'>
						<Card
							size='small'
							styles={{ body: CARD_IMG_BODY_STYLES }}
							title={tVendorBranch('branch_details.tabs.info.banner')}
							extra={
								<DeleteImgBtn
									type='banner'
									onDelete={onDeleteBranchImages}
									disabled={!canUploadBanner}
								/>
							}
						>
							<ImageBox
								buttonDisabled={!canUploadBanner}
								bordered={false}
								image={images.banner}
								onAddEditBtnClick={handleOpenBranchBannerEditModal}
								extraInfo={tVendorBranch('branch_details.tabs.info.banner_upload_alert')}
							/>
						</Card>
					</Col>
				</Row>
			</Space>

			{/* Branch IMAGE && BANNER MODAL */}
			<ImgEditModal
				open={editingBranchImg || editingBranchBanner}
				title={tVendorBranch(
					`branch_details.tabs.info.${editingBranchBanner ? 'banner_edit_modal' : 'image_edit_modal'}`
				)}
				uploadFileDataName={editingBranchBanner ? 'store_banner' : 'store_image'}
				maxImgSize={editingBranchBanner ? EImgFileSize.BANNER : EImgFileSize.STORE}
				onCloseModal={handleCloseBranchImgOrBannerEditModal}
				httpRequestConfig={(data: FormData) =>
					editingBranchBanner
						? VENDOR_BRANCH_IMAGE_BANNER_API.updateBanner(vendorId, branchId, data)
						: VENDOR_BRANCH_IMAGE_BANNER_API.updateImage(vendorId, branchId, data)
				}
			/>
		</>
	);
};

export default InfoTabContent;
