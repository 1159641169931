import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'store';
import { getIntegrationTypeSettings } from 'store/selectors/integrations';
import { useNewHttpClient, useVendor } from 'hooks';
import { VENDOR_BRANCH_API } from 'configs/api';
import { IIntegrationUpdateRequest, IVendorBranchIntegrationSettingsUpdatePayload } from 'types/api';
import { Nullable } from 'types/common';
import { IBranchTabProps } from '../Branches/BranchDetails/types';

export interface IIntegrationContextData {
	loading: boolean;
	settings: Nullable<IIntegrationUpdateRequest>;
	settingsValidation: Nullable<'error' | 'success'>;

	handleFetch: () => Promise<void>;
	handleSaveBranchSettings: (formValues: Record<string, string>) => Promise<void> | undefined;
}

const IntegrationContext = createContext<IIntegrationContextData>({} as IIntegrationContextData);

export const useIntegrationControllerFromProvider = () => {
	return useContext(IntegrationContext);
};

export const IntegrationProvider = ({ vendorId, branchId, children }: PropsWithChildren<IBranchTabProps>) => {
	const integrationData = useIntegrationController({ vendorId, branchId });
	const { integrationType } = useVendor();

	// ! selectors
	const vendorDataIntegrationSettings = useSelector(getIntegrationTypeSettings(integrationType));

	useEffect(() => {
		if (!vendorDataIntegrationSettings?.isIntegration) {
			integrationData.handleFetch();
		}

		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorDataIntegrationSettings]);

	return <IntegrationContext.Provider value={integrationData}>{children}</IntegrationContext.Provider>;
};

export const useIntegrationController = ({ vendorId, branchId }: IBranchTabProps): IIntegrationContextData => {
	const [settings, setSettings] = useState<Nullable<IIntegrationUpdateRequest>>(null);
	const [settingsValidation, setSettingsValidation] = useState<Nullable<'error' | 'success'>>(null);

	// ! http clients
	const fetchHttpClient = useNewHttpClient<IIntegrationUpdateRequest>();
	const saveHttpClient = useNewHttpClient<IIntegrationUpdateRequest>();

	// ! handlers
	const handleFetch = useCallback(() => {
		return fetchHttpClient.request({
			requestConfig: VENDOR_BRANCH_API.integrations.get(vendorId, branchId),
			successCallback: (response) => {
				setSettings(response);
				setSettingsValidation(response?.deliverect?.is_active ? 'success' : 'error');
			},
			errorCallback: () => {
				setSettingsValidation('error');
			},
		});
	}, [branchId, vendorId]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSaveBranchSettings = (formValues: Record<string, string>) => {
		if (!formValues || !settings) return;

		const payload: IVendorBranchIntegrationSettingsUpdatePayload = {
			type: settings.type,
			...formValues,
		};

		return saveHttpClient.request({
			requestConfig: VENDOR_BRANCH_API.integrations.patch(vendorId, branchId, payload),
			successCallback: (response) => {
				setSettings(response);
			},
		});
	};

	useEffect(() => {
		handleFetch();
	}, [handleFetch]);

	return {
		loading: fetchHttpClient.isLoading || saveHttpClient.isLoading,
		settings,
		settingsValidation,

		handleFetch,
		handleSaveBranchSettings,
	};
};
