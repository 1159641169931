import { IRoleItem, IUserProfile } from 'types/api';

// ! Admins
export interface IUserLogin {
	access_token: string;
	refresh_token: string;
	user: IUserProfile;
}

// ! Operation Users
export enum EOperationsUserType {
	SUPER_ADMIN = 'super_admin',
	ADMIN = 'admin',
	REGULAR = 'regular',
}

export interface IOperationUser {
	id: number;
	name: string;
	email: string;
	user_type: EOperationsUserType;
	roles?: IRoleItem[];
}

export interface ICreateOperationUserForm {
	name: string;
	email: string;
	password: string;
}

export interface ICreateOperationUserData extends ICreateOperationUserForm {
	role_ids: number[];
	permission_ids: number[];
}

export interface IUpdateOperationUserDetailsData extends ICreateOperationUserData {}

export interface IUpdateOperationUserRolesItem {
	id: number;
	country_id: number;
}

export interface IUpdateOperationUserRolesPermissionsData {
	permission_ids: number[];
	roles: IUpdateOperationUserRolesItem[];
}
