import { ESupportedLanguages } from '../common';

// ! enums & types
export enum ESupportPagesPurpose {
	GENERAL = 'general',
	CARI_UNLIMITED = 'cari_unlimited',
}

export enum ESupportPagesStatus {
	DRAFT = 'draft',
	PUBLISHED = 'published',
}

export enum EPageTarget {
	CUSTOMER = 'customer',
	DRIVER = 'driver',
	VENDOR_POS = 'vendor_pos',
	WEB = 'web',
}

// ! interfaces
export interface IPageType {
	id: number;
	slug: string;
}

export interface ISupportPages {
	id: number;
	title: string;
	link: string;
	content: string;
	language: ESupportedLanguages;
	status: ESupportPagesStatus;
	type: IPageType;
	target: EPageTarget;
}

// * create new page
export interface ICreateNewSupportPageData {
	title: ISupportPages['title'];
	type_id: ISupportPages['type']['id'];
	content: ISupportPages['content'];
	purpose?: ESupportPagesPurpose;
	status: ISupportPages['status'];
	language: ISupportPages['language'];
	target: ISupportPages['target'];
}

// * update page
export interface IUpdateNewSupportPageConfig {
	pageId: string | number;
	data: ICreateNewSupportPageData;
}
