import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { getAccessibleCountryList, getSelectedCountry, getSettingsSlice } from 'store/selectors';
import { setSelectedAreas, setSelectedCountry, setSelectedTimezone } from 'store/slices/settings';
import { useGlobalConfigs } from 'hooks';
import { Clock, UTC_TIME_ZONE } from 'hooks/useLocaleTimeUtils';
import { transformCountriesListToSelectOptions } from './helper';
import { TIMEZONE_STORAGE_KEY } from 'configs/common';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { SelectAreasDrawerOpenButton } from 'components/SelectAreasDrawer/SelectAreasDrawerOpenButton';
import styles from './CountrySelect.module.css';
import dayjs from 'dayjs';
import { Flex, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ClockCircleOutlined } from '@ant-design/icons';

export const CountrySelect: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { storage } = useGlobalConfigs();
	const { t: tCommon } = useTranslation('common');

	// ! selectors
	const countriesList = useSelector(getAccessibleCountryList);
	const { selectedCountryId, selectedTimezone, language: selectedAppLanguage } = useSelector(getSettingsSlice);
	const currentCountry = useSelector(getSelectedCountry);

	// ! useMemo
	const timeZoneOptions: DefaultOptionType[] = useMemo(() => {
		const resultList: DefaultOptionType[] = [];

		resultList.push({
			label: (
				<Clock
					timeZoneName={tCommon('header.time_zones.current_timezone')}
					timezone={dayjs.tz.guess()}
				/>
			),
			value: dayjs.tz.guess(),
		});

		resultList.push({
			label: <Clock timeZoneName={tCommon('header.time_zones.utc_timezone')} />,
			value: UTC_TIME_ZONE,
		});

		const countryTimeZoneList =
			currentCountry?.timezones.map(
				(tz): DefaultOptionType => ({ value: tz.name, label: <Clock timezone={tz.name} /> })
			) ?? [];

		resultList.push(...countryTimeZoneList);

		return resultList;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCountry?.timezones, selectedAppLanguage]);

	// ! handlers
	// * timeZone management
	const handleTimeZoneSelect = (newSelectedTimezone: string) => {
		dispatch(
			setSelectedTimezone({
				newSelectedTimezone,
				storage,
			})
		);
	};

	// * country management
	const onCountrySelectChange = (chosenCountryId: string) => {
		if (selectedCountryId != null && selectedCountryId.toString() !== chosenCountryId) {
			dispatch(setSelectedCountry({ newSelectedCountryId: +chosenCountryId }));
			handleClearAreas();

			handleTimeZoneSelect(dayjs.tz.guess());

			navigate(ABSOLUTE_ROUTES.LOBBY);
		}
	};

	// * area management
	const handleClearAreas = () => {
		dispatch(
			setSelectedAreas({
				selectedAreas: [],
				storage,
			})
		);
	};

	// ! effects
	// * init time zone based on local storage
	useEffect(() => {
		const timeZone = storage.get(TIMEZONE_STORAGE_KEY);

		let newValidatedTimeZone = UTC_TIME_ZONE;

		try {
			if (!timeZone) throw new Error();

			// test if timeZone is valid. It will return error if not
			dayjs().tz(timeZone);

			newValidatedTimeZone = timeZone;
		} catch (error) {
			newValidatedTimeZone = dayjs.tz.guess();
		}

		dispatch(setSelectedTimezone({ newSelectedTimezone: newValidatedTimeZone, storage }));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!countriesList?.length) {
		return null;
	}

	// ! render
	return (
		<Flex
			gap={8}
			wrap='wrap'
		>
			<Select
				size='small'
				onChange={onCountrySelectChange}
				value={selectedCountryId?.toString()}
				className={styles.country_select}
				options={transformCountriesListToSelectOptions(countriesList)}
			/>

			<SelectAreasDrawerOpenButton />

			<Select<string>
				suffixIcon={<ClockCircleOutlined />}
				showSearch={false}
				size='small'
				placeholder={tCommon('header.time_zone_placeholder')}
				options={timeZoneOptions}
				value={selectedTimezone}
				className={styles.time_zone_select}
				onChange={handleTimeZoneSelect}
			/>
		</Flex>
	);
};
