import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { BlockOutlined } from '@ant-design/icons';

const CategoryList = lazy(() => import('pages/Categories/CategoryList'));
const AddCategory = lazy(() => import('pages/Categories/AddCategory'));

export const CATEGORIES_ROUTES: TRouteObject = {
	path: 'categories',
	element: <ListParamsRouteWrapper key='categories' />,
	handle: {
		translationKey: 'categories',
		permissions: [APP_PERMISSIONS.category.view],
		icon: <BlockOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <CategoryList />,
			handle: {
				translationKey: 'categories',
				permissions: [APP_PERMISSIONS.category.view],
				icon: <BlockOutlined />,
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: false,
			},
		},
		{
			path: 'new',
			element: <AddCategory />,
			handle: {
				translationKey: 'add_new_category',
				permissions: [APP_PERMISSIONS.category.add],
				showOnNav: false,
			},
		},
	],
};
