import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { PAYMENTS_API } from 'configs/api';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { ERefundAllowedPaymentMethods, IRefundMethodItem } from 'types/api';
import { IListResponse, ISelectOption } from 'types/common';
import { IOrderRefundModalProps } from './types';
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';

const OrderRefundModal: FC<IOrderRefundModalProps> = ({ isOpen, form, onOk, onCancel, confirmLoading, title }) => {
	const { t: tCustomersRefundModal } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.orders.refund_modal',
	});
	const [refundOptions, setRefundOptions] = useState<ISelectOption[]>([]);

	const selectedPaymentMethodId = useWatch('payment_method_id', form);

	// ! http client
	const fetchMethodsHttpClient = useNewHttpClient<IListResponse<IRefundMethodItem>>();

	const fetchRefundMethods = useCallback(() => {
		fetchMethodsHttpClient.request({
			requestConfig: PAYMENTS_API.listRefunds(),
			successCallback: (response) => {
				const options = response.data.map<ISelectOption>(({ id, title }) => ({
					value: id,
					label: title,
				}));

				setRefundOptions(options);
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! memos
	const fieldsConfig = useMemo(() => {
		const paymentMethodsData = fetchMethodsHttpClient.response?.data;

		if (!paymentMethodsData) return;

		const selectedPaymentMethodData = paymentMethodsData.filter((item) => item.id === selectedPaymentMethodId)[0];

		const data = {
			showBankAmountField: [
				ERefundAllowedPaymentMethods.BANK_ACCOUNT,
				ERefundAllowedPaymentMethods.PROMOCODE_AND_BANK_ACCOUNT,
			].includes(selectedPaymentMethodData.name),

			showPromotionAmountField: [
				ERefundAllowedPaymentMethods.PROMOCODE,
				ERefundAllowedPaymentMethods.PROMOCODE_AND_BANK_ACCOUNT,
			].includes(selectedPaymentMethodData.name),
		};

		return data;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPaymentMethodId]);

	// ! effects
	useEffect(() => {
		fetchRefundMethods();
	}, [fetchRefundMethods]);

	useEffect(() => {
		if (!refundOptions?.length || !form || !isOpen) return;
		form.setFieldsValue({ payment_method_id: refundOptions[0].value });
	}, [form, refundOptions, isOpen]);

	// ! render
	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			title={title}
			open={isOpen}
			okText={tCustomersRefundModal('ok_text')}
			confirmLoading={confirmLoading || fetchMethodsHttpClient.isLoading}
			onOk={onOk}
			onCancel={onCancel}
		>
			<Form
				form={form}
				layout='vertical'
				name='order-refund-form'
			>
				<Form.Item
					name='payment_method_id'
					label={tCustomersRefundModal('payment_method')}
					initialValue={refundOptions[0]?.value}
					rules={[{ required: true }]}
				>
					<Select
						options={refundOptions}
						loading={fetchMethodsHttpClient.isLoading}
					/>
				</Form.Item>

				<Form.Item
					name='comment'
					label={tCustomersRefundModal('comment')}
					initialValue=''
					rules={[{ required: true }]}
				>
					<Input.TextArea rows={3} />
				</Form.Item>

				{fieldsConfig?.showBankAmountField && (
					<Form.Item
						name='amount'
						label={tCustomersRefundModal('amount_to_bank')}
						initialValue={0}
						rules={[{ required: true }]}
					>
						<InputNumber className='w-100' />
					</Form.Item>
				)}

				{fieldsConfig?.showPromotionAmountField && (
					<Form.Item
						name='promotion_amount'
						label={tCustomersRefundModal('amount_to_promocode')}
						initialValue={0}
						rules={[{ required: true }]}
					>
						<InputNumber className='w-100' />
					</Form.Item>
				)}

				<Form.Item
					name='cost_to_vendor'
					label={tCustomersRefundModal('cost_to_vendor')}
					initialValue={0}
					rules={[{ required: true }]}
				>
					<InputNumber className='w-100' />
				</Form.Item>

				<Form.Item
					name='cost_to_platform'
					label={tCustomersRefundModal('cost_to_platform')}
					initialValue={0}
					rules={[{ required: true }]}
				>
					<InputNumber className='w-100' />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default OrderRefundModal;
