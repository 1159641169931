import { lazy } from 'react';
import { TRouteObject } from 'configs/routes';
import { ELogisticsOptimizerTabsKeys } from '../types';
import TranslatedLabel from 'components/TranslatedLabel';
import { BankOutlined, ClockCircleOutlined } from '@ant-design/icons';

// ! Lazy module imports
const DeliveryFeeTabContent = lazy(() => import('../Tabs/DeliveryFeeTabContent'));
const DeliveryTimeTabContent = lazy(() => import('../Tabs/DeliveryTimeTabContent'));

export const LOGISTICS_OPTIMIZER_TABS: Record<ELogisticsOptimizerTabsKeys, TRouteObject> = {
	[ELogisticsOptimizerTabsKeys.DELIVERY_TIME]: {
		path: ELogisticsOptimizerTabsKeys.DELIVERY_TIME,
		handle: {
			key: ELogisticsOptimizerTabsKeys.DELIVERY_TIME,
			label: (
				<TranslatedLabel
					nameSpace='logistics'
					i18nKey={`${ELogisticsOptimizerTabsKeys.DELIVERY_TIME.replaceAll('-', '_')}.title`}
				/>
			),
			icon: <ClockCircleOutlined />,
			permissions: [],
		},
		element: <DeliveryTimeTabContent />,
	},
	[ELogisticsOptimizerTabsKeys.DELIVERY_FEE]: {
		path: ELogisticsOptimizerTabsKeys.DELIVERY_FEE,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='logistics'
					i18nKey={`${ELogisticsOptimizerTabsKeys.DELIVERY_FEE.replaceAll('-', '_')}.title`}
				/>
			),
			icon: <BankOutlined />,
			permissions: [],
		},
		element: <DeliveryFeeTabContent />,
	},
};

export const LOGISTICS_OPTIMIZER_TAB_ROUTES: TRouteObject[] = Object.values(LOGISTICS_OPTIMIZER_TABS);

export const DEFAULT_LOGISTICS_OPTIMIZER_DETAILS_TAB_KEY = ELogisticsOptimizerTabsKeys.DELIVERY_TIME;
