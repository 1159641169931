import { ReactNode } from 'react';
import { TDispatcherOrderTimelineStepKey } from './types';
import { CarOutlined, CheckCircleOutlined, ClockCircleOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';

export const DISPATCHER_ORDER_TIMELINE_STEPS_ICONS: Record<TDispatcherOrderTimelineStepKey, ReactNode> = {
	new_at: <ClockCircleOutlined />,
	preparing_at: <ShopOutlined />,
	assigned_at: <CarOutlined />,
	ready_at: <ShopOutlined />,
	picked_at: <ShopOutlined />,
	on_route_at: <CarOutlined />,
	arrived_at: <CarOutlined />,
	delivered_at: <UserOutlined />,
	completed_at: <CheckCircleOutlined />,
	heading_to_store_at: <CarOutlined />,
	arrived_at_store_at: <CarOutlined />,
};
