import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import {
	IApproveCustomerSupportRequestPayload,
	ICustomerSupportRequest,
	IRejectCustomerSupportRequestPayload,
} from 'types/api';

export const CUSTOMER_SUPPORT_REQUESTS_API = {
	list: (params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/customer-support/requests',
		method: 'GET',
		params,
	}),

	get: (id: ICustomerSupportRequest['id']): THttpRequestConfig => ({
		url: `operations/customer-support/requests/${id}`,
		method: 'GET',
	}),

	reject: (id: ICustomerSupportRequest['id'], data: IRejectCustomerSupportRequestPayload): THttpRequestConfig => ({
		url: `operations/customer-support/requests/${id}/reject`,
		method: 'PATCH',
		data,
	}),

	approve: (id: ICustomerSupportRequest['id'], data: IApproveCustomerSupportRequestPayload): THttpRequestConfig => ({
		url: `operations/customer-support/requests/${id}/approve`,
		method: 'PATCH',
		data,
	}),

	requestTypesList: (): THttpRequestConfig => ({
		url: 'operations/customer-support/requests/request-types',
		method: 'GET',
	}),

	availablePaymentMethods: (id: ICustomerSupportRequest['id']): THttpRequestConfig => ({
		url: `operations/customer-support/requests/${id}/payment-methods`,
		method: 'GET',
	}),
};
