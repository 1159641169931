import { IMenu, IMenuEntity } from 'types/api';
import { convertCoordinatesToKey } from 'pages/Vendors/VendorDetails/Tabs/Menu/MenuEditor/helper';

export const convertMenuIntoEntityMap = (menu: IMenu, map: Map<string, IMenuEntity>): Map<string, IMenuEntity> => {
	const sub_category_id = undefined;
	const product_id = undefined;

	menu?.categories.forEach((category) => {
		const category_id = category.id;

		map.set(
			convertCoordinatesToKey({
				category_id,
				sub_category_id,
				product_id,
			}),
			category
		);

		category.products?.forEach((product) => {
			const product_id = product.id;

			map.set(
				convertCoordinatesToKey({
					category_id,
					sub_category_id,
					product_id,
				}),
				product
			);
		});

		category.sub_categories?.forEach((subCategory) => {
			const sub_category_id = subCategory.id;

			map.set(
				convertCoordinatesToKey({
					category_id,
					sub_category_id,
					product_id,
				}),
				subCategory
			);

			subCategory.products?.forEach((product) => {
				const product_id = product.id;

				map.set(
					convertCoordinatesToKey({
						category_id,
						sub_category_id,
						product_id,
					}),
					product
				);
			});
		});
	});

	return map;
};
