import { EStatus } from 'types/common';

export enum EPaymentsSupportStatus {
	SUPPORTED = 'supported',
	NOT_SUPPORTED = 'not_supported',
}

export enum EPaymentsEnabledStatus {
	ENABLED = 'enabled',
	DISABLED = 'disabled',
}

export enum EPaymentMethods {
	CASH = 'cash',
	CREDIT_CARD = 'credit_card',
	KNET = 'knet',
	WALLET = 'wallet',
	MADA = 'mada',
	APPLE_PAY = 'apple_pay',
	UAE_CC = 'uae_cc',
	BANK_ACCOUNT = 'bank_account',
	PROMOTION = 'promotion',
	PROMOCODE = 'promocode',
	PROMOCODE_AND_BANK_ACCOUNT = 'promocode_and_bank_account',
	NO_PAYMENT_METHOD = 'no_payment_method',
}

export enum ERefundAllowedPaymentMethods {
	BANK_ACCOUNT = EPaymentMethods.BANK_ACCOUNT,
	PROMOCODE = EPaymentMethods.PROMOCODE,
	PROMOCODE_AND_BANK_ACCOUNT = EPaymentMethods.PROMOCODE_AND_BANK_ACCOUNT,
}

export interface IPaymentListItemResponse {
	id: number;
	name: string;
	title: string;
	status: EStatus;

	supports_refund: EPaymentsSupportStatus; // control on BE level
	refund_enabled: EPaymentsEnabledStatus;

	supports_scheduled_orders: EPaymentsSupportStatus;

	supports_wallet_credit: EPaymentsSupportStatus; // control on BE level
	wallet_credit_enabled: EPaymentsEnabledStatus;
}

export interface IUpdatePaymentData
	extends Partial<
		Pick<
			IPaymentListItemResponse,
			'status' | 'refund_enabled' | 'supports_scheduled_orders' | 'wallet_credit_enabled'
		>
	> {}

export interface IRefundMethodItem {
	id: number;
	name: ERefundAllowedPaymentMethods;
	title: string;
}
