import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { UndoOutlined } from '@ant-design/icons';

const CustomerSupportRequestsList = lazy(() => import('pages/CustomerSupportRequests/CustomerSupportRequestsList'));
const CustomerSupportRequestsDetails = lazy(
	() => import('pages/CustomerSupportRequests/CustomerSupportRequestsDetails')
);

export const CUSTOMER_SUPPORT_REQUESTS_ROUTES: TRouteObject = {
	path: 'customer-support-requests',
	element: <ListParamsRouteWrapper key='customer-support-requests' />,
	handle: {
		translationKey: 'customer_support_requests',
		defaultListParams: LIST_DEFAULT_PARAMS,
		permissions: [APP_PERMISSIONS.customer_support_requests.view],
		icon: <UndoOutlined />,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <CustomerSupportRequestsList />,
			handle: {
				showOnNav: false,
			},
		},
		{
			path: ':customerSupportRequestId',
			element: <CustomerSupportRequestsDetails />,
			handle: {
				translationKey: 'customer_support_requests_details',
				permissions: [APP_PERMISSIONS.customer_support_requests.view],
				showOnNav: false,
			},
		},
	],
};
