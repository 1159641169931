import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getIntegrationTypeSettings } from 'store/selectors/integrations';
import { useBranch, useNewHttpClient, useVendor } from 'hooks';
import { VENDOR_BRANCH_API } from 'configs/api';
import { App, Button } from 'antd';

export const IntegrationManualMenuSync: FC = () => {
	const { t: tIntegrations } = useTranslation('integrations');
	const navigate = useNavigate();

	const { vendorId, integrationType } = useVendor();
	const { branchId } = useBranch();
	const { message } = App.useApp();

	const HttpClient = useNewHttpClient();

	// ! selectors
	const integrationSettings = useSelector(getIntegrationTypeSettings(integrationType));

	// ! handlers
	const handleManualMenuSync = () => {
		HttpClient.request({
			requestConfig: VENDOR_BRANCH_API.VENDOR_MENU_SNAPSHOT_API.manualSync(vendorId, branchId),
			successCallback: () => {
				message.success(tIntegrations('manual_sync.success'));

				setTimeout(() => navigate(''), 3000);
			},
		});
	};

	// ! render
	return (
		<Button
			loading={HttpClient.isLoading}
			onClick={handleManualMenuSync}
		>
			{tIntegrations('manual_sync.action_button_label', { integration_type: integrationSettings?.name })}
		</Button>
	);
};
