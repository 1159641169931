import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { getIntegrationTypeSettings } from 'store/selectors/integrations';
import { useVendor } from 'hooks';
import { IMenuIntegrationAlertProps } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { IntegrationManualMenuSync } from '../../Tabs/Menus/IntegrationManualMenuSync';
import { Alert } from 'antd';

const IntegrationAlert: FC<IMenuIntegrationAlertProps> = ({ pageType, ...props }) => {
	const { t: tIntegrations } = useTranslation('integrations');
	const { integrationType } = useVendor();

	// ! selectors
	const integrationSettings = useSelector(getIntegrationTypeSettings(integrationType));

	const pageTranslationName = tIntegrations(`page_types.${pageType}`);

	// ! render
	return (
		<Alert
			type='warning'
			showIcon
			action={integrationSettings?.manual_menu_sync ? <IntegrationManualMenuSync /> : undefined}
			message={
				<TranslatedLabel
					nameSpace='integrations'
					i18nKey='warning'
					extra={{
						page_name: pageTranslationName,
						integration_type: integrationSettings?.name,
					}}
				/>
			}
			{...props}
		/>
	);
};

export default IntegrationAlert;
