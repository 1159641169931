import { ReactNode } from 'react';
import { EOrderStatus } from 'types/api';

export interface IOrderStatusTagProps {
	status: EOrderStatus;
	description?: ReactNode;
}

export const EOrderStatusTranslationKeys = Object.entries(EOrderStatus)
	.filter(([key, _]) => isNaN(Number(key)))
	.reduce<Record<EOrderStatus, string>>((acc, [key, value]) => {
		acc[value as EOrderStatus] = key.toLowerCase();
		return acc;
	}, {} as Record<EOrderStatus, string>);
