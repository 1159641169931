import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useLogos from 'hooks/useLogos';
import styles from './NewLayout.module.css';
import layoutStyles from './NewLayout.module.css';
import { Button, Input, Layout, Menu, Space } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { SettingTwoTone } from '@ant-design/icons';

export const NewLayout: FC = () => {
	const items = [{ label: 'ABMasterSwitchPage', key: 'ABMasterSwitchPage' }];

	const [isLoggedIn, setIstLoggedIn] = useState(false);
	const [selectedKeys, setSelectedKeys] = useState(['ABMasterSwitchPage']);

	const LogoComp: FC = () => {
		const { fullLogo } = useLogos();
		return (
			<div className={styles.logo}>
				<img
					alt='logo'
					src={fullLogo}
					className={styles.app_logo}
				/>
				<SettingTwoTone
					twoToneColor={['var(--ant-primary-7)', 'var(--ant-primary-1)']}
					className={styles.sub_logo}
				/>
			</div>
		);
	};

	const onPseudoLogin = (password: string) => {
		if (password === 'AB#123') setIstLoggedIn(true);
	};

	return (
		<Layout
			className={styles.layout}
			hasSider
		>
			<>
				<Layout.Sider
					theme='light'
					className={styles.sider}
				>
					<LogoComp />

					{isLoggedIn && (
						<Menu
							mode='inline'
							theme='light'
							items={items}
							className={styles.menu}
							selectedKeys={selectedKeys}
							onSelect={({ key }) => setSelectedKeys([key])}
						/>
					)}

					<Space
						className='w-100'
						direction='vertical'
						style={{ padding: 16 }}
					>
						<Button
							href='/dashboard'
							className='w-100'
							shape='round'
						>
							Return to App
						</Button>
					</Space>
				</Layout.Sider>
				<Layout.Content>
					{!isLoggedIn ? (
						<Content className={layoutStyles.content}>
							<Space
								className='w-100'
								direction='vertical'
								style={{ padding: 16 }}
							>
								<label>Password</label>
								<Input.Password onChange={(e) => onPseudoLogin(e.target.value)} />
							</Space>
						</Content>
					) : (
						<Outlet />
					)}
				</Layout.Content>
			</>
		</Layout>
	);
};
