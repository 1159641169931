import { FC, PropsWithChildren } from 'react';
import { Card, CardProps } from 'antd';

export const CustomLayoutCard: FC<CardProps & PropsWithChildren> = ({ children, style, styles, ...props }) => {
	const newStyles = { border: 0, borderRadius: 16, height: '100%', ...style };
	const headStyles = { border: 'none', padding: '24px 24px 0 24px', ...styles?.header };
	const bodyStyles = { height: '100%', ...styles?.body };

	return (
		<Card
			style={newStyles}
			styles={{
				header: headStyles,
				body: bodyStyles,
			}}
			{...props}
		>
			{children}
		</Card>
	);
};
