import { THttpRequestConfig } from 'hooks';

export const PERMISSIONS_LIST: THttpRequestConfig = {
	url: 'operations/permissions',
	method: 'GET',
};

export const getUserPermissionConfig = (countryId: number): THttpRequestConfig => ({
	url: 'operations/profile/permissions',
	method: 'GET',
	params: {
		country_id: countryId,
	},
});
