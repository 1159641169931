import { FC, Suspense } from 'react';
import { Outlet, RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AppBrandProvider, GlobalConfigProvider } from 'hooks';
import VERSIONING_ROUTES from 'hooks/FeatureVariantControl/SettingsPages/VersionRoutes';
import { ABConfigProvider } from 'hooks/FeatureVariantControl/useFeatureVariant';
import { processRoutes } from 'utils/processRoutes';
import { DEFAULT_OPERATIONS_ROUTES_CONFIG, TRouteObject } from 'configs/routes';
import { SelectAreasDrawerProvider } from 'contexts/areasDrawer';
import { AuthProvider } from 'contexts/auth';
import { CountryRouteWrapper, RequireAuthWrapper, UrlIdParamValidatorWrapper } from 'appWrappers';
import Spinner from 'components/Spinner';
import ErrorPage from 'pages/ErrorPage';
import Layout from 'pages/Layout';
import Login from 'pages/Login';

const ROUTES_CONFIG: TRouteObject[] = [
	{
		element: (
			<GlobalConfigProvider>
				<AppBrandProvider>
					<ABConfigProvider>
						<AuthProvider>
							<SelectAreasDrawerProvider>
								{/* // translations suspense spinner */}
								<Suspense fallback={<Spinner />}>
									<Outlet />
								</Suspense>
							</SelectAreasDrawerProvider>
						</AuthProvider>
					</ABConfigProvider>
				</AppBrandProvider>
			</GlobalConfigProvider>
		),
		errorElement: (
			<ErrorPage title={`Sorry, the page you tried to visit isn't available.\nPlease, refresh the page.`} />
		),
		children: [
			{
				path: 'login',
				element: <Login />,
				children: [],
			},
			VERSIONING_ROUTES,
			{
				path: ':countryIsoTwoCode/*',
				element: (
					<RequireAuthWrapper>
						<CountryRouteWrapper>
							<Layout>
								<UrlIdParamValidatorWrapper />
							</Layout>
						</CountryRouteWrapper>
					</RequireAuthWrapper>
				),
				children: processRoutes(DEFAULT_OPERATIONS_ROUTES_CONFIG),
			},
			{
				path: '*',
				element: (
					<RequireAuthWrapper>
						<CountryRouteWrapper />
					</RequireAuthWrapper>
				),
			},
		],
	},
];

const APP_ROUTER = createBrowserRouter(ROUTES_CONFIG as RouteObject[], { basename: '/' });

const App: FC = () => {
	return (
		<RouterProvider
			router={APP_ROUTER}
			fallbackElement={
				<Spinner
					defaultAntdSpinner
					hasOverlay={false}
				/>
			}
		/>
	);
};

export default App;
