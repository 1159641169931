import { EOrderDeliveryStatus } from 'types/api';
import { ISelectOption } from 'types/common';
import { TranslatedLabel } from 'components/TranslatedLabel/TranslatedLabel';

const DEFAULT_ORDER_DELIVERY_STATUS_FILTERS_LIST: ISelectOption[] = [
	{
		value: EOrderDeliveryStatus.DRIVER_NOT_ASSIGNED,
		label: <TranslatedLabel i18nKey='order_delivery_status.driver_not_assigned' />,
	},
	{
		value: EOrderDeliveryStatus.DRIVER_IS_ASSIGNED,
		label: <TranslatedLabel i18nKey='order_delivery_status.driver_is_assigned' />,
	},
	{
		value: EOrderDeliveryStatus.DRIVER_HEADING_TO_STORE,
		label: <TranslatedLabel i18nKey='order_delivery_status.driver_heading_to_store' />,
	},
	{
		value: EOrderDeliveryStatus.DRIVER_ARRIVED_AT_STORE,
		label: <TranslatedLabel i18nKey='order_delivery_status.driver_arrived_at_store' />,
	},
];

export const ORDER_PREPARING_DELIVERY_STATUS_FILTERS_CONFIG: ISelectOption[] = [
	...DEFAULT_ORDER_DELIVERY_STATUS_FILTERS_LIST,
];
export const ORDER_READY_DELIVERY_STATUS_FILTERS_CONFIG: ISelectOption[] = [
	{
		value: EOrderDeliveryStatus.ORDER_PICKED_UP,
		label: <TranslatedLabel i18nKey='order_delivery_status.order_picked_up' />,
	},
	...DEFAULT_ORDER_DELIVERY_STATUS_FILTERS_LIST,
];
export const ORDER_DISPATCHED_DELIVERY_STATUS_FILTERS_CONFIG: ISelectOption[] = [
	{
		value: EOrderDeliveryStatus.DRIVER_ON_ROUTE_TO_CUSTOMER,
		label: <TranslatedLabel i18nKey='order_delivery_status.driver_on_route_to_customer' />,
	},
	{
		value: EOrderDeliveryStatus.DRIVER_ARRIVED_AT_CUSTOMER,
		label: <TranslatedLabel i18nKey='order_delivery_status.driver_arrived_at_customer' />,
	},
	{
		value: EOrderDeliveryStatus.DRIVER_DELIVERED_THE_ORDER,
		label: <TranslatedLabel i18nKey='order_delivery_status.driver_delivered_the_order' />,
	},
	{
		value: EOrderDeliveryStatus.DRIVER_COLLECTED_THE_PAYMENT,
		label: <TranslatedLabel i18nKey='order_delivery_status.driver_collected_the_payment' />,
	},
	{
		value: EOrderDeliveryStatus.DISPUTED,
		label: <TranslatedLabel i18nKey='order_delivery_status.disputed' />,
	},
];

export const ORDER_ALL_DELIVERY_STATUS_FILTERS_CONFIG: ISelectOption[] = [
	...ORDER_READY_DELIVERY_STATUS_FILTERS_CONFIG,
	...ORDER_DISPATCHED_DELIVERY_STATUS_FILTERS_CONFIG,
];
