import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import store from 'store';
import { getValidUrlCountryId } from 'utils/getValidUrlCountryId';
import { IStorageUtils, getSelectedAreasFromLocalStorage } from 'utils/localStorage';
import { isNumber } from 'utils/validationUtils/isNumber';
import { AUTH_API, AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY, REFRESH_TOKEN_URL } from 'configs/api';
import { APP_LANGUAGE_STORAGE_KEY } from 'configs/common';
import { EHttpStatus } from 'types/api';
import { ESupportedLanguages } from 'types/common';
import { AppError } from 'exceptions/AppError';

export type THttpFunction = AxiosInstance;

export const http = (appConfig_api_url: string, storageUtils: IStorageUtils): THttpFunction => {
	// Create axios instance
	const axiosInstance = axios.create({
		baseURL: appConfig_api_url,
		headers: {
			'Content-Type': 'application/json',
		},
		paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
	});

	// Set the interceptors
	axiosInstance.interceptors.request.use(
		(config) => {
			const authToken = storageUtils.get(AUTH_TOKEN_KEY);

			// * token and language
			if (authToken?.length) {
				const acceptLanguage =
					config?.headers?.accept_language ??
					localStorage.getItem(APP_LANGUAGE_STORAGE_KEY) ??
					ESupportedLanguages.ENGLISH;

				config.headers = config.headers.concat({
					Authorization: `Bearer ${authToken}`,
					'accept-language': acceptLanguage,
					// 'ngrok-skip-browser-warning': 'skip', // NGROK Local Testing
				});
			}

			// * country
			const userAccessibleCountries = store.getState()?.countries.accessibleCountries;
			const country_id = getValidUrlCountryId(userAccessibleCountries);

			if (!config.params?.country_id && isNumber(country_id)) {
				// add country_id filter if not set
				config.params = { ...config.params, country_id };
			}

			// * areas
			const area_ids = getSelectedAreasFromLocalStorage(storageUtils);

			if (!config.params?.area_ids && area_ids.length > 0) {
				// add area_ids filter if not set
				config.params = { ...config.params, area_ids };
			}

			// * return
			return config;
		},
		(error) => Promise.reject(error)
	);

	// ! UNAUTHORIZED INTERCEPTOR
	axiosInstance.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalConfig = error.config;

			if (originalConfig?.url !== REFRESH_TOKEN_URL) {
				// ACCESS TOKEN WAS EXPIRED
				if (error.response?.status === EHttpStatus.UNAUTHORIZED && !originalConfig._retry) {
					originalConfig._retry = true;
					const refreshToken = storageUtils.get(REFRESH_TOKEN_KEY);
					if (refreshToken && refreshToken?.length > 0) {
						try {
							const { data } = await axiosInstance(AUTH_API.refreshToken(refreshToken));

							storageUtils.set(AUTH_TOKEN_KEY, data.access_token);
							storageUtils.set(REFRESH_TOKEN_KEY, data.refresh_token);
							return axiosInstance(originalConfig);
						} catch (_error) {
							// REFRESH TOKEN FAILED
							return Promise.reject(
								new AppError('Your session has expired. Please Signin again.', EHttpStatus.UNAUTHORIZED)
							);
						}
					}
				}
			}

			return Promise.reject(error.response ?? error);
		}
	);

	return axiosInstance;
};
