import { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './PageLayout.module.css';

interface IPageLayoutProps {
	header: ReactNode;
	withInnerPadding?: boolean;
}

const PageLayout: FC<PropsWithChildren<IPageLayoutProps>> = ({ header, withInnerPadding, children }) => {
	return (
		<div className='flex flex-column h-100'>
			{header}

			<div className={`${withInnerPadding ? styles.inner_page_padding : ''} flex-fill overflow-scroll-y`}>
				{children}
			</div>
		</div>
	);
};

export default PageLayout;
