import { Dispatch } from 'react';
import { EMenuType, IMenuEntity, IMenuTreeCoordinate } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';
import { IVendorTabProps } from '../../types';

export enum ETreeNodeType {
	CATEGORY,
	SUBCATEGORY,
	PRODUCT,
}

export interface MenuTreeNode<T = IMenuEntity> {
	id: string;
	nodeType: ETreeNodeType;

	nodeElem: T;

	children: Record<string, MenuTreeNode<T>>;

	currentCoordinate: IMenuTreeCoordinate;
	sortOrder: number;
}

export interface IMenuAddEntityData {
	ids: number[];
	parent_category_id?: number;
}

export interface IProductModalProps {
	isOpen: boolean;
	confirmLoading: boolean;
	defaultSelectedList: number[];
	targetParentId?: number;
	onClose: TEmptyFunction;
	onConfirm: (data: IMenuAddEntityData) => void;
}

export interface IMenuSelectedElement {
	coord: IMenuTreeCoordinate;
	data: IMenuEntity;
}

export enum EEditMode {
	EDIT,
	VIEW,
	VIEW_INTEGRATION,
}

export interface IMenuEditorProps extends IVendorTabProps {
	editMode: EEditMode;
	isBranchMenu?: boolean;

	onConsultProduct: (menuProductCoordinate: IMenuTreeCoordinate) => void;
}

export interface IToggleSnoozeProductData extends IMenuTreeCoordinate {
	number_of_minutes: number;
}

export interface ISortTarget {
	items: IMenuEntity[];
	treeLevel: ETreeNodeType;
	currentCoordinates: Nullable<IMenuTreeCoordinate>;
}

export interface IMenuTreeProps {
	parentCoordinate?: Nullable<IMenuTreeCoordinate>;

	setAddProductCategoryTarget: Dispatch<IMenuSelectedElement>;
	data: Record<string, MenuTreeNode>;
	currentTreeLevel?: ETreeNodeType;
	handleTreeNodeDelete: Dispatch<IMenuTreeCoordinate[]>;
	setAddSubCategoryModalOpen: TEmptyFunction;
	setAddProductModalOpen: TEmptyFunction;
	isParentChecked?: boolean;
	isBranchMenu?: boolean;
	setNodeChecked: Dispatch<IMenuTreeCoordinate>;
	checkedStatus: Map<string, boolean>;
	menuType: EMenuType;
	editMode: EEditMode;

	onConsultProduct: (menuProductCoordinate: IMenuTreeCoordinate) => void;
}
