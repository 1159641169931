import { ICustomerCustomerSupportRequestListItemResponse } from 'types/api';
import { ICustomerCustomerSupportRequestTableData } from './types';

export const transformCustomerCustomerSupportRequestToTableData = (
	request: ICustomerCustomerSupportRequestListItemResponse
): ICustomerCustomerSupportRequestTableData => {
	return {
		key: request.id,
		...request,
	};
};
