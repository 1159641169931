import { LocalTimeDate } from 'hooks';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER, ID_COLUMN_WIDTH } from 'configs/common';
import { ICustomerCustomerSupportRequestTableData } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { ColumnsType } from 'antd/es/table';

export const DEFAULT_CUSTOMER_CUSTOMER_SUPPORT_REQUESTS_COLUMNS: ColumnsType<ICustomerCustomerSupportRequestTableData> =
	[
		{
			key: 'id',
			title: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey='id'
					extra={{ keyPrefix: 'customer_details.tabs.customer_support_requests.entity' }}
				/>
			),
			dataIndex: 'id',
			width: ID_COLUMN_WIDTH,
			filteredValue: null,
		},
		{
			key: 'request_date',
			title: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey='request_date'
					extra={{ keyPrefix: 'customer_details.tabs.customer_support_requests.entity' }}
				/>
			),
			filteredValue: null,
			render: (_, { info }) => <LocalTimeDate date={info.request_date} />,
		},
		{
			key: 'resolved_date',
			title: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey='resolved_date'
					extra={{ keyPrefix: 'customer_details.tabs.customer_support_requests.entity' }}
				/>
			),
			filteredValue: null,
			render: (_, { info }) => {
				if (!info.resolved_date) return DEFAULT_EMPTY_VALUE_PLACEHOLDER;

				return <LocalTimeDate date={info.resolved_date} />;
			},
		},
	];
