import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { getSettingsSlice } from 'store/selectors';
import { useAreasDrawer } from 'hooks';
import styles from './SelectAreasDrawer.module.css';
import { Button, Flex, Tooltip } from 'antd';
import { FlagOutlined } from '@ant-design/icons';

export const SelectAreasDrawerOpenButton: FC = () => {
	const { t: tAreas } = useTranslation('areas', { keyPrefix: 'drawer' });
	const { openDrawer } = useAreasDrawer();

	// ! selectors
	const { selectedAreasIds } = useSelector(getSettingsSlice);

	// ! return
	return (
		<Tooltip title={tAreas('tooltip')}>
			<Button
				className={styles.open_btn}
				size='small'
				onClick={() => openDrawer()}
			>
				<Flex gap={8}>
					<FlagOutlined />
					{tAreas('selected', {
						count: selectedAreasIds?.length ?? 0,
					})}
				</Flex>
			</Button>
		</Tooltip>
	);
};
