import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import { EBranchTabsKeys } from './types';
import CITCIcon from 'components/CitcIcon';
import TranslatedLabel from 'components/TranslatedLabel';
import BranchIntegrationContent from './Tabs/BranchIntegrationContent';
import BranchTimingsTabContent from './Tabs/BranchTimingsTabContent';
import CitcBranchTabContent from './Tabs/CitcBranchTabContent/CitcBranchTabContent';
import InfoTabContent from './Tabs/Details';
import BranchMenusTabContent from './Tabs/Menus';
import OperationsAreaContent from './Tabs/OperationsArea';
import { ApiOutlined, ClockCircleOutlined, InfoOutlined, MenuOutlined, RadiusSettingOutlined } from '@ant-design/icons';

const branchTabsPathKeyPrefix = 'vendor_details.tabs.branches.branch_details.tabs';

export const BRANCH_DETAILS_TAB_ROUTES: Record<EBranchTabsKeys, TRouteObject> = {
	[EBranchTabsKeys.DETAILS]: {
		path: EBranchTabsKeys.DETAILS,
		element: <InfoTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='info.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			icon: <InfoOutlined />,
			permissions: [APP_PERMISSIONS.vendor.store.view],
		},
	},
	[EBranchTabsKeys.MENUS]: {
		path: EBranchTabsKeys.MENUS,
		element: <BranchMenusTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='menus.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			icon: <MenuOutlined />,
			permissions: [APP_PERMISSIONS.vendor.menu.view],
		},
	},
	[EBranchTabsKeys.TIMINGS]: {
		path: EBranchTabsKeys.TIMINGS,
		element: <BranchTimingsTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='timings.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			icon: <ClockCircleOutlined />,
			permissions: [APP_PERMISSIONS.vendor.shift.view],
		},
	},
	[EBranchTabsKeys.OPERATION_AREA]: {
		path: EBranchTabsKeys.OPERATION_AREA,
		element: <OperationsAreaContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='operation_area.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			icon: <RadiusSettingOutlined />,
			permissions: [APP_PERMISSIONS.vendor.store.operation_area.view],
		},
	},
	[EBranchTabsKeys.INTEGRATION]: {
		path: EBranchTabsKeys.INTEGRATION,
		element: <BranchIntegrationContent />,
		handle: {
			icon: <ApiOutlined />,
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='integration.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			permissions: [APP_PERMISSIONS.vendor.view],
		},
	},
	[EBranchTabsKeys.CITC]: {
		path: EBranchTabsKeys.CITC,
		element: <CitcBranchTabContent />,
		handle: {
			icon: <CITCIcon />,
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='citc.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			permissions: [APP_PERMISSIONS.citc.view],
			country_white_list: [ESupportedCountriesIsoTwoCodes.SAUDI_ARABIA],
		},
	},
};

export const BRANCH_DETAILS_ROUTES: TRouteObject[] = Object.values(BRANCH_DETAILS_TAB_ROUTES);
