import { isFeatureAvailableByRouteMatchABVersion } from 'hooks/FeatureVariantControl/helpers';
import { IFeatureVariantCurrentSelection } from 'hooks/FeatureVariantControl/types';
import { pipe, traverseRouteTree } from 'utils/processRoutes';
import { IPermissionConfigItem } from 'configs/permissions';
import { DEFAULT_OPERATIONS_ROUTES_CONFIG, TRouteObject } from 'configs/routes';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';

export const isAllowed = (userPermissions: string[], routePermissions: IPermissionConfigItem[]) => {
	if (!routePermissions.length) return true;

	return routePermissions.every((routePermission) => userPermissions.includes(routePermission.code));
};

const filterByABFeatures = (currentSelectedABs: IFeatureVariantCurrentSelection) => (route: TRouteObject) => {
	if (isFeatureAvailableByRouteMatchABVersion(currentSelectedABs, [route])) {
		return route;
	}

	return undefined;
};

const filterByPermissions =
	(userPermissions: Array<IPermissionConfigItem['code']>, isSuperAdmin: boolean) => (route: TRouteObject) => {
		if (isSuperAdmin) return route;

		const routeAllowed = isAllowed(userPermissions, route.handle?.permissions ?? []);

		if (!routeAllowed) return undefined;

		return route;
	};

const filterRouteByCountry = (selectedCountryIsoTwoCode: ESupportedCountriesIsoTwoCodes) => (route: TRouteObject) => {
	if (!(route?.handle?.country_white_list?.length > 0)) {
		return route;
	}

	if (route?.handle?.country_white_list?.includes(selectedCountryIsoTwoCode)) {
		return route;
	}

	return undefined;
};

export const getAllowedRoutes = (
	routes: TRouteObject[] = [...DEFAULT_OPERATIONS_ROUTES_CONFIG],
	userPermissions: Array<IPermissionConfigItem['code']>,
	currentSelectedABs: IFeatureVariantCurrentSelection,
	selectedCountryIsoTwoCode: ESupportedCountriesIsoTwoCodes,
	isSuperAdmin: boolean = false
) => {
	return traverseRouteTree(
		routes as TRouteObject[],
		pipe(
			filterByABFeatures(currentSelectedABs),
			filterByPermissions(userPermissions, isSuperAdmin),
			filterRouteByCountry(selectedCountryIsoTwoCode as ESupportedCountriesIsoTwoCodes)
		)
	);
};
