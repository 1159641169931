import { useSyncExternalStore } from 'react';
import { useGlobalConfigs } from 'hooks';
import { IStorageUtils } from 'utils/localStorage';
import * as yup from 'yup';

export const NAV_COLLAPSE_STATE_KEY = 'nav_collapse_state';

export enum ESidebarState {
	COLLAPSED = 'collapsed',
	EXPANDED = 'expanded',
}

export const getLastSidebarStateFromLocalStorage = (storage: IStorageUtils): ESidebarState => {
	const yupSchema = yup.mixed().oneOf(Object.values(ESidebarState)).required();

	try {
		let state = storage.get(NAV_COLLAPSE_STATE_KEY);

		return (yupSchema.validateSync(state) as ESidebarState) ?? ESidebarState.EXPANDED;
	} catch (error) {
		return ESidebarState.EXPANDED;
	}
};

const syncExternalStore = {
	getSnapshot: (storage: IStorageUtils) => () => getLastSidebarStateFromLocalStorage(storage),
	subscribe: (listener: () => void) => {
		window.addEventListener(NAV_COLLAPSE_STATE_KEY, listener);

		return () => void window.removeEventListener(NAV_COLLAPSE_STATE_KEY, listener);
	},
};

export const useSidebarState = () => {
	const { storage } = useGlobalConfigs();

	const sidebarState = useSyncExternalStore<ESidebarState>(
		syncExternalStore.subscribe,
		syncExternalStore.getSnapshot(storage)
	);

	const isCollapsed = sidebarState === ESidebarState.COLLAPSED;

	const setSidebarState = (newValue: ESidebarState) => {
		storage.set(NAV_COLLAPSE_STATE_KEY, newValue);
		// On localStorage.setItem, the storage event is only triggered on other tabs and windows.
		// So we manually dispatch a storage event to trigger the subscribe function on the current window as well.
		window.dispatchEvent(new StorageEvent(NAV_COLLAPSE_STATE_KEY, { key: NAV_COLLAPSE_STATE_KEY, newValue }));
	};

	return { isCollapsed, sidebarState, setSidebarState };
};
