import { THttpRequestConfig } from 'hooks';

export const LIVE_TRACKING_API = {
	getLocationBranchesListConfig: (neBounds: string, swBounds: string): THttpRequestConfig => ({
		url: 'operations/live-tracking/stores',
		method: 'GET',
		params: {
			ne: neBounds,
			sw: swBounds,
		},
	}),
};
