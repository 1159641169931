import { NavigateFunction } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { AppDispatch } from 'store';
import { EHttpStatus } from 'types/api';
import { AppError } from 'exceptions/AppError';
import { processClientLogout } from 'contexts/auth/helpers';
import { IStorageUtils } from './localStorage';
import { MessageInstance } from 'antd/es/message/interface';

const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong!';

export type THandleError = (error: AxiosResponse | AppError, displayErrorMsg?: boolean) => void;

export const handleError =
	(
		storage: IStorageUtils,
		navigate: NavigateFunction,
		message: MessageInstance,
		dispatch: AppDispatch
	): THandleError =>
	(error: AxiosResponse | AppError, displayErrorMsg?: boolean) => {
		const status = error?.status;
		const errorMessage: string = error?.data?.message ?? DEFAULT_ERROR_MESSAGE;

		const isClientAppError = AppError.isError(error);
		const isUnauthorized = status === EHttpStatus.UNAUTHORIZED;

		// Access and refresh tokens were expired and this is not custom error
		if (isClientAppError && isUnauthorized) {
			processClientLogout(storage, navigate, dispatch);

			message.error(errorMessage);
			return;
		}

		if (displayErrorMsg) message.error(errorMessage, 5);

		console.warn('There was an error!', error);
	};
