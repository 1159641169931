import { CARI_UNLIMITED_COUNTRIES_WHITE_LIST } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ECustomerTabsKeys } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import CustomerAddresses from './AddressesTab';
import CustomerCariUnlimitedManagerTab from './CustomerCariUnlimitedManagerTab';
import CustomerSupportRequestsTab from './CustomerSupportRequestsTab';
import DetailsTab from './DetailsTab';
import OrdersTab from './OrdersTab';
import WalletHistoryTab from './WalletHistoryTab';
import { AimOutlined, FireOutlined, FundOutlined, InfoOutlined, ShopOutlined, UndoOutlined } from '@ant-design/icons';

export const CUSTOMER_DETAILS_TAB_ROUTES: Record<ECustomerTabsKeys, TRouteObject> = {
	[ECustomerTabsKeys.DETAILS]: {
		path: ECustomerTabsKeys.DETAILS,
		element: <DetailsTab />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey={`customer_details.tabs.${ECustomerTabsKeys.DETAILS}.title`}
				/>
			),
			permissions: [],
			icon: <InfoOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.ADDRESSES]: {
		path: ECustomerTabsKeys.ADDRESSES,
		element: <CustomerAddresses />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey={`customer_details.tabs.${ECustomerTabsKeys.ADDRESSES}.title`}
				/>
			),
			permissions: [APP_PERMISSIONS.customer.address.view],
			icon: <AimOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.ORDERS]: {
		path: ECustomerTabsKeys.ORDERS,
		element: <OrdersTab />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey={`customer_details.tabs.${ECustomerTabsKeys.ORDERS}.title`}
				/>
			),
			permissions: [APP_PERMISSIONS.order.view],
			icon: <ShopOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.WALLET_HISTORY]: {
		path: ECustomerTabsKeys.WALLET_HISTORY,
		element: <WalletHistoryTab />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey={`customer_details.tabs.${ECustomerTabsKeys.WALLET_HISTORY}.title`}
				/>
			),
			permissions: [APP_PERMISSIONS.customer.wallet.view_history],
			icon: <FundOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.CARI_UNLIMITED_MANAGER]: {
		path: ECustomerTabsKeys.CARI_UNLIMITED_MANAGER,
		element: <CustomerCariUnlimitedManagerTab />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey={`customer_details.tabs.${ECustomerTabsKeys.CARI_UNLIMITED_MANAGER}.title`}
				/>
			),
			country_white_list: CARI_UNLIMITED_COUNTRIES_WHITE_LIST,
			permissions: [APP_PERMISSIONS.cari_unlimited.view, APP_PERMISSIONS.cari_unlimited.subscription.view],
			icon: <FireOutlined />,
			showOnNav: false,
		},
	},
	[ECustomerTabsKeys.CUSTOMER_SUPPORT_REQUEST]: {
		path: ECustomerTabsKeys.CUSTOMER_SUPPORT_REQUEST,
		element: <CustomerSupportRequestsTab />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='customers'
					i18nKey={`customer_details.tabs.${ECustomerTabsKeys.CUSTOMER_SUPPORT_REQUEST}.title`}
				/>
			),
			permissions: [APP_PERMISSIONS.customer_support_requests.view],
			icon: <UndoOutlined />,
			showOnNav: false,
		},
	},
};

export const DEFAULT_CUSTOMER_DETAILS_TAB_KEY = ECustomerTabsKeys.DETAILS;

export const CUSTOMER_TAB_ROUTES: TRouteObject[] = Object.values(CUSTOMER_DETAILS_TAB_ROUTES);
