import { ReactNode, lazy } from 'react';
import { Navigate, RouteObject, generatePath } from 'react-router-dom';
import { IFeatureVariantCurrentSelection } from 'hooks';
import { AREAS_ROUTES } from 'pages/Areas/routes.areas';
import { BANNERS_ROUTES } from 'pages/Banners/routes.banners';
import { BIN_CARD_SEGMENTS_ROUTES } from 'pages/BinCardSegments/routes.bin-card-segments';
import { CARI_UNLIMITED_ROUTES } from 'pages/CariUnlimited';
import { CATEGORIES_ROUTES } from 'pages/Categories/routes.categories';
import { COMPANIES_ROUTES } from 'pages/Companies/routes.companies';
import { CUSTOMER_SUPPORT_FLOW_ROUTES } from 'pages/CustomerSupportFlow/routes.customer-support-flow';
import { CUSTOMER_SUPPORT_REQUESTS_ROUTES } from 'pages/CustomerSupportRequests/routes.customer-support-requests';
import { CUSTOMER_ROUTES } from 'pages/Customers/routes.customers';
import { DRIVERS_ROUTES } from 'pages/Drivers/routes/routes.drivers';
import ErrorPage403 from 'pages/ErrorPage403/ErrorPage403';
import { GENERAL_SETTINGS_ROUTES } from 'pages/GeneralSettings/routes.general-settings';
import { INVOICES_ROUTES } from 'pages/Invoices/routes.invoices';
import { LOGISTICS_OPTIMIZER_ROUTES } from 'pages/LogisticsOptimizer/routes/routes.logisticsOptimizer';
import { MANAGE_ROLES_ROUTES } from 'pages/ManageUsersAndRoles/ManageRoles/routes.manage-roles';
import { MANAGE_USERS_ROUTES } from 'pages/ManageUsersAndRoles/ManageUsers/routes.manage-users';
import { ORDER_ROUTES } from 'pages/Orders/routes/routes.orders';
import { IRoutePermission } from 'pages/PagePermissions/helpers';
import { PAGE_PERMISSIONS_ROUTES } from 'pages/PagePermissions/routes-page-permissions';
import { PAYMENT_TYPES_ROUTES } from 'pages/PaymentTypes/routes.payment-types';
import { PROMOTIONS_ROUTES } from 'pages/Promotions/routes.promotions';
import { PUSH_NOTIFICATIONS_ROUTES } from 'pages/PushNotifications/routes.push-notifications';
import { RATINGS_ROUTES } from 'pages/Ratings/routes.ratings';
import { REFUNDS_ROUTES } from 'pages/Refunds/routes.refunds';
import { REQUIRED_DOCUMENTS_ROUTES } from 'pages/RequiredDocuments/routes.required-documents';
import { SUPPLIERS_ROUTES } from 'pages/Suppliers/routes.suppliers';
import { SUPPORT_PAGES_ROUTES } from 'pages/SupportPages/routes.support-pages';
import { VEHICLE_TYPES_ROUTES } from 'pages/VehicleTypes/routes.vehicle-types';
import { EVendorTabsKeys } from 'pages/Vendors/VendorDetails/Tabs/types';
import { VENDOR_ROUTES } from 'pages/Vendors/routes/routes.vendors';
import { VERTICAL_ROUTES } from 'pages/Verticals/routes.verticals';
import { EntryLobby } from 'pages/_EntryLobby/EntryLobby';
import { APP_PERMISSIONS, IPermissionConfigItem, SUPER_ADMIN_PERMISSION } from './permissions';
import {
	DashboardOutlined,
	OrderedListOutlined,
	PlayCircleOutlined,
	SettingOutlined,
	TeamOutlined,
} from '@ant-design/icons';

export interface INavMenuRoute {
	id?: string;
	index?: boolean;
	showOnNav: boolean;
	path?: string;
	icon?: ReactNode;
	translationKey?: string;
	isNavigateable?: boolean;
	children?: INavMenuRoute[];
	element?: ReactNode;
	permissions?: IRoutePermission[];
	defaultListParams?: Record<string, string>;
}

export interface IRouteObjectHandle {
	children?: TRouteObject[];
	handle?: {
		translationKey?: string;
		permissions?: IPermissionConfigItem[];
		country_white_list?: string[];
		icon?: ReactNode;
		showOnNav?: boolean;
		isNavigateable?: boolean;
		mustHaveABs?: IFeatureVariantCurrentSelection;
	} & any;
}

export type TRouteObject = Omit<RouteObject, 'handle' | 'children'> & IRouteObjectHandle;

export const ABSOLUTE_ROUTES = {
	// ! NEW ROUTES TO BE USED
	LOGIN: '/login',

	LOBBY: '/',

	DASHBOARD: '/:countryIsoTwoCode/dashboard',
	DISPATCHER: '/:countryIsoTwoCode/dispatcher',
	LIVE_TRACKING: '/:countryIsoTwoCode/live-tracking',
	// ! categories
	CATEGORIES_LIST: '/:countryIsoTwoCode/categories',
	CATEGORIES_NEW: '/:countryIsoTwoCode/categories/new',
	// ! verticals
	VERTICAL_LIST: '/:countryIsoTwoCode/verticals',
	VERTICAL_NEW: '/:countryIsoTwoCode/verticals/new',
	VERTICAL_DETAILS: '/:countryIsoTwoCode/verticals/:verticalId',
	// ! orders
	ORDER_LIST: '/:countryIsoTwoCode/orders',
	ORDER_DETAILS: '/:countryIsoTwoCode/orders/:orderId/details',
	ORDER_DETAILS_TAB: '/:countryIsoTwoCode/orders/:orderId/:orderTab',
	// ! companies
	COMPANIES: '/:countryIsoTwoCode/companies',
	// ! vendors
	VENDOR_LIST: '/:countryIsoTwoCode/vendors',
	VENDOR_NEW: '/:countryIsoTwoCode/vendors/new',
	VENDOR_DETAILS: '/:countryIsoTwoCode/vendors/:vendorId/details',
	VENDOR_DETAILS_EDIT: '/:countryIsoTwoCode/vendors/:vendorId/edit',
	VENDOR_DETAILS_PRODUCT_LIST: '/:countryIsoTwoCode/vendors/:vendorId/products',
	VENDOR_DETAILS_PRODUCT_CREATE: '/:countryIsoTwoCode/vendors/:vendorId/products/create',
	VENDOR_DETAILS_PRODUCT_DETAILS: '/:countryIsoTwoCode/vendors/:vendorId/products/:productId',
	VENDOR_DETAILS_MENU_LIST: '/:countryIsoTwoCode/vendors/:vendorId/menus',
	VENDOR_DETAILS_MENU_DETAILS: '/:countryIsoTwoCode/vendors/:vendorId/menus/:menuId',
	VENDOR_DETAILS_WORKING_SHIFTS_LIST: '/:countryIsoTwoCode/vendors/:vendorId/working-shifts',
	VENDOR_DETAILS_WORKING_SHIFTS_DETAILS: '/:countryIsoTwoCode/vendors/:vendorId/working-shifts/:workShiftId',
	VENDOR_DETAILS_BRANCH_LIST: '/:countryIsoTwoCode/vendors/:vendorId/branches',
	VENDOR_DETAILS_BRANCH_DETAILS_EDIT: '/:countryIsoTwoCode/vendors/:vendorId/branches/:branchId/edit',
	VENDOR_DETAILS_BRANCH_TAB: '/:countryIsoTwoCode/vendors/:vendorId/branches/:branchId/:branchTab',
	VENDOR_DETAILS_BRANCH_DETAILS: '/:countryIsoTwoCode/vendors/:vendorId/branches/:branchId/details',
	VENDOR_DETAILS_BRANCH_ADD: '/:countryIsoTwoCode/vendors/:vendorId/branches/add',
	VENDOR_TAB: '/:countryIsoTwoCode/vendors/:vendorId/:vendorTab',
	VENDOR_PRESENCE: '/:countryIsoTwoCode/vendors/:vendorId/presence',
	VENDOR_DETAILS_BRANCH_MENU: '/:countryIsoTwoCode/vendors/:vendorId/branches/:branchId/menus',
	VENDOR_DETAILS_BRANCH_PRESENCE: '/:countryIsoTwoCode/vendors/:vendorId/branches/:branchId/presence',
	// ! campaigns
	CARI_PRIZE_TAB: '/:countryIsoTwoCode/promotions/cari-prize-campaigns/:campaignId/:campaignTab',
	PROMOTION_TABS: '/:countryIsoTwoCode/promotions/:promotionType/:promotionId/:promotionTab',

	// ! refunds
	REFUND_LIST: '/:countryIsoTwoCode/refunds',
	REFUND_DETAILS: '/:countryIsoTwoCode/refunds/:refundId',

	// ! users
	USER_CUSTOMER_LIST: '/:countryIsoTwoCode/users/customers',
	USER_CUSTOMER_DETAILS: '/:countryIsoTwoCode/users/customers/:customerId',
	USER_CUSTOMER_TAB: '/:countryIsoTwoCode/users/customers/:customerId/:customerTab',
	USER_DRIVER_TAB: '/:countryIsoTwoCode/users/drivers/:driverId/:driverTab',
	USER_DRIVER_LIST: '/:countryIsoTwoCode/users/drivers',
	USER_DRIVER_DETAILS: '/:countryIsoTwoCode/users/drivers/:driverId',
	// ! ratings
	RATINGS_DRIVERS: '/:countryIsoTwoCode/ratings/drivers',
	RATINGS_BRANCHES: '/:countryIsoTwoCode/ratings/branches',
	// ! notifications
	NOTIFICATION_LIST: '/:countryIsoTwoCode/notifications',
	NOTIFICATION_NEW: '/:countryIsoTwoCode/notifications/new',
	// ! logistics
	LOGISTICS_OPTIMIZER: '/:countryIsoTwoCode/logistics/deliveries',
	LOGISTICS_OPTIMIZER_TAB: '/:countryIsoTwoCode/logistics/deliveries/:deliveriesTab',
	LOGISTICS_VENDOR_RANKINGS: '/:countryIsoTwoCode/logistics/rankings',
	// ! suppliers
	MANAGEMENT_SUPPLIER_LIST: '/:countryIsoTwoCode/management/suppliers',
	MANAGEMENT_SUPPLIER_NEW: '/:countryIsoTwoCode/management/suppliers/new',
	// ! application versions
	MANAGEMENT_VEHICLE_TYPES: '/:countryIsoTwoCode/management/vehicle-types',
	MANAGEMENT_SUPPORT_PAGES: '/:countryIsoTwoCode/management/support-pages',
	MANAGEMENT_REQUIRED_DOCUMENTS_LIST: '/:countryIsoTwoCode/management/required-documents',
	MANAGEMENT_REQUIRED_DOCUMENTS_NEW: '/:countryIsoTwoCode/management/required-documents/new',
	// ! role-permission management
	MANAGEMENT_MANAGE_USERS_AND_ROLES_USERS: '/:countryIsoTwoCode/management/manage-users-and-roles/users',
	MANAGEMENT_MANAGE_USERS_AND_ROLES_ROLES: '/:countryIsoTwoCode/management/manage-users-and-roles/roles',
	MANAGEMENT_MANAGE_USERS_AND_ROLES_PAGE_PERMISSIONS:
		'/:countryIsoTwoCode/management/manage-users-and-roles/page-permissions',
	MANAGEMENT_PAYMENT_TYPES: '/:countryIsoTwoCode/management/payment-types',
	MANAGEMENT_GENERAL_SETTINGS: '/:countryIsoTwoCode/management/general-settings',

	// ! user settings
	CHANGE_USER_DETAILS: '/:countryIsoTwoCode/change-user-details',

	// ! Areas
	MANAGEMENT_AREAS: '/:countryIsoTwoCode/management/areas',
	MANAGEMENT_AREA_NEW: '/:countryIsoTwoCode/management/areas/new',
	MANAGEMENT_AREA_DETAILS: '/:countryIsoTwoCode/management/areas/:areaId',

	// ! CARI UNLIMITED
	MANAGEMENT_CARI_UNLIMITED_TABS: '/:countryIsoTwoCode/cari-unlimited/:tab',
	MANAGEMENT_CARI_UNLIMITED_PLAN_DETAILS: '/:countryIsoTwoCode/cari-unlimited/plans/:planId',

	// ! Customer Support Requests
	CUSTOMER_SUPPORT_REQUESTS_DETAILS: '/:countryIsoTwoCode/customer-support-requests/:requestId',

	// ! FORBIDDEN
	FORBIDDEN: '/forbidden',
	404: '/404',

	APP_FEATURE_VARIANT_TOGGLE_BOARD: '/version-switch-board',
};

export const getVendorBranchDetailsPageUrl = (
	countryIsoTwoCode: string = '',
	vendorId: number,
	branchId: number
): string => {
	if (!vendorId || !branchId) return '';

	return generatePath(ABSOLUTE_ROUTES.VENDOR_DETAILS_BRANCH_DETAILS, {
		countryIsoTwoCode: String(countryIsoTwoCode).toLowerCase(),
		vendorId,
		vendorTab: EVendorTabsKeys.BRANCHES,
		branchId,
	});
};

// ! Lazy module imports
const ErrorPage404 = lazy(() => import('pages/ErrorPage404'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Dispatcher = lazy(() => import('pages/Dispatcher'));

const ChangeUserDetails = lazy(() => import('pages/ChangeUserDetails'));

// live-tracking
const LiveTracking = lazy(() => import('pages/LiveTracking'));

// ! Routes Config
export const DEFAULT_OPERATIONS_ROUTES_CONFIG: TRouteObject[] = [
	{
		index: true,
		element: <EntryLobby />,
		handle: {},
	},
	// ! Dashboard
	{
		path: 'dashboard',
		element: <Dashboard />,
		handle: {
			translationKey: 'dashboard',
			permissions: [APP_PERMISSIONS.dashboard.view],
			icon: <DashboardOutlined />,
			showOnNav: true,
		},
	},
	// ! Dispatcher
	{
		path: 'dispatcher',
		element: <Dispatcher />,
		handle: {
			translationKey: 'dispatcher',
			permissions: [APP_PERMISSIONS.dispatcher.view],
			icon: <OrderedListOutlined />,
			showOnNav: true,
		},
	},
	// ! Live Tracking
	{
		path: 'live-tracking',
		element: <LiveTracking />,
		handle: {
			translationKey: 'live_tracking',
			permissions: [APP_PERMISSIONS.live_tracking.view],
			icon: <PlayCircleOutlined />,
			showOnNav: true,
		},
	},
	// ! Categories
	CATEGORIES_ROUTES,

	// ! Verticals
	VERTICAL_ROUTES,

	// ! Orders
	ORDER_ROUTES,

	// ! Companies
	COMPANIES_ROUTES,

	// ! Vendors
	VENDOR_ROUTES,

	// ! Campaign Management
	PROMOTIONS_ROUTES,
	// ! Banners
	BANNERS_ROUTES,

	// ! Refunds
	REFUNDS_ROUTES,

	// ! Invoices
	INVOICES_ROUTES,

	// ! Customer Support Requests
	CUSTOMER_SUPPORT_REQUESTS_ROUTES,

	// ! CARI Unlimited
	CARI_UNLIMITED_ROUTES,

	// ! Users
	{
		path: 'users',
		handle: {
			translationKey: 'users',
			icon: <TeamOutlined />,
			permissions: [],
			showOnNav: true,
			isNavigateable: false,
		},
		children: [
			// ! Customers
			CUSTOMER_ROUTES,

			// ! Drivers
			DRIVERS_ROUTES,
		],
	},

	// ! Ratings
	RATINGS_ROUTES,

	// ! Push Notifications
	PUSH_NOTIFICATIONS_ROUTES,

	// ! Logistics
	LOGISTICS_OPTIMIZER_ROUTES,

	// ! Management pages
	{
		path: 'management',
		handle: {
			translationKey: 'management',
			icon: <SettingOutlined />,
			permissions: [],
			showOnNav: true,
			isNavigateable: false,
		},
		children: [
			// ! BIN Card Segments
			BIN_CARD_SEGMENTS_ROUTES,

			// ! Areas
			AREAS_ROUTES,

			// ! Customer Support Flow
			CUSTOMER_SUPPORT_FLOW_ROUTES,

			// ! Suppliers
			SUPPLIERS_ROUTES,

			// ! Vehicle Types
			VEHICLE_TYPES_ROUTES,

			// ! Support Pages
			SUPPORT_PAGES_ROUTES,

			// ! Required Documents
			REQUIRED_DOCUMENTS_ROUTES,

			// ! Roles & Permissions
			{
				path: 'manage-users-and-roles',
				handle: {
					translationKey: 'manage_users_and_roles',
					icon: <SettingOutlined />,
					showOnNav: true,
					isNavigateable: false,
				},
				children: [MANAGE_ROLES_ROUTES, MANAGE_USERS_ROUTES, PAGE_PERMISSIONS_ROUTES],
			},

			PAYMENT_TYPES_ROUTES,

			GENERAL_SETTINGS_ROUTES,

			{
				path: 'access-versioning',
				element: <Navigate to={ABSOLUTE_ROUTES.APP_FEATURE_VARIANT_TOGGLE_BOARD} />,
				handle: {
					translationKey: 'access_versioning',
					permissions: [SUPER_ADMIN_PERMISSION],
					icon: <SettingOutlined />,
					showOnNav: true,
				},
			},
		],
	},
	// ! User Password Management
	{
		path: 'change-user-details',
		element: <ChangeUserDetails />,
		handle: {
			translationKey: 'change_user_details',
			permissions: [APP_PERMISSIONS.profile.update],
			showOnNav: false,
		},
	},
	// ! Others
	{
		path: 'forbidden',
		element: <ErrorPage403 />,
		handle: {
			showOnNav: false,
		},
	},
	{
		path: '404',
		element: <ErrorPage404 />,
		handle: {
			showOnNav: false,
		},
	},
	{
		path: '*',
		element: <ErrorPage404 />,
		handle: {
			showOnNav: false,
		},
	},
];
