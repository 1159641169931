import { lazy } from 'react';
import { ProductProvider } from 'hooks/useProduct';
import { TRouteObject } from 'configs/routes';

const BranchMenusTabContent = lazy(() => import('./BranchMenusTabContent'));
const ProductSnapshot = lazy(() => import('./ProductSnapshot'));
const BranchMenuView = lazy(() => import('./BranchMenuView'));

export const VENDOR_BRANCH_MENUS_ROUTES: TRouteObject[] = [
	{
		index: true,
		element: <BranchMenusTabContent />,
	},
	{
		path: ':menuId',
		children: [
			{
				index: true,
				element: <BranchMenuView />,
			},
			{
				path: 'product/:productId',
				element: (
					<ProductProvider>
						<ProductSnapshot />
					</ProductProvider>
				),
				handle: {
					showOnNav: false,
				},
			},
		],
	},
];
