import { createSlice } from '@reduxjs/toolkit';
import { IPermissionItem } from 'types/api';
import { Nullable } from 'types/common';
import { IActionArg, IDefaultStateFields } from '../types';

// ! initial state
interface IPermissionsSlice extends IDefaultStateFields {
	permissionsList: Nullable<IPermissionItem[]>;
}

const permissionsSliceInitialState: IPermissionsSlice = {
	permissionsList: null,

	// own state
	loading: false,
};

const permissionsSlice = createSlice({
	name: 'permissionsSlice',
	initialState: permissionsSliceInitialState,
	reducers: {
		setPermissionsList: (state, action: IActionArg<IPermissionItem[]>) => {
			state.permissionsList = action.payload;
		},
	},
});

export const { setPermissionsList } = permissionsSlice.actions;
export default permissionsSlice.reducer;
