import { FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';

// --
export const ProtectedAccessByCountryWrapper: FC<{
	children?: ReactNode;
	country_white_list?: string[];
}> = ({ country_white_list = [], children = <Outlet /> }) => {
	const selectedCountryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	if (!selectedCountryIsoTwoCode) {
		return null;
	}

	if (!country_white_list.includes(selectedCountryIsoTwoCode as ESupportedCountriesIsoTwoCodes)) {
		return (
			<Navigate
				to={ABSOLUTE_ROUTES[404]}
				replace
			/>
		);
	}

	return <>{children}</>;
};
