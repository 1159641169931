import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { CustomerSupportFlowDetailsProvider } from 'contexts/customerSupportFlowDetailsProvider';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { RadarChartOutlined } from '@ant-design/icons';

const CustomerSupportFlow = lazy(() => import('pages/CustomerSupportFlow'));
const CustomerSupportFlowDetails = lazy(() => import('pages/CustomerSupportFlow/CustomerSupportFlowDetails'));

export const CUSTOMER_SUPPORT_FLOW_ROUTES: TRouteObject = {
	path: 'customer-support-flow',
	element: <ListParamsRouteWrapper key='customer-support-flow' />,
	handle: {
		translationKey: 'customer_support_flow',
		permissions: [APP_PERMISSIONS.customer_support_flow.view],
		icon: <RadarChartOutlined />,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <CustomerSupportFlow />,
		},
		{
			path: ':customerSupportFlowIdentifier',
			element: (
				<CustomerSupportFlowDetailsProvider>
					<CustomerSupportFlowDetails />,
				</CustomerSupportFlowDetailsProvider>
			),
			handle: {
				translationKey: 'customer_support_flow_details',
				permissions: [APP_PERMISSIONS.customer_support_flow.view],
				showOnNav: false,
			},
		},
	],
};
