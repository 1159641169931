import { ECariUnlimitedSubscriptionCancellationMethods } from 'types/api';
import TranslatedLabel from 'components/TranslatedLabel';

export const CANCELLATION_OPTIONS = Object.values(ECariUnlimitedSubscriptionCancellationMethods).map((value) => ({
	value: value,
	label: (
		<TranslatedLabel
			nameSpace='customers'
			i18nKey={value}
			extra={{
				keyPrefix:
					'customer_details.tabs.cari_unlimited_manager.subscription_cancellation_modal.cancellation_options',
			}}
		/>
	),
}));
