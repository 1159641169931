import { FC, memo } from 'react';
import { Property } from 'csstype';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { EBranchStatus } from 'types/api';
import { Nullable } from 'types/common';
import LoadingTag from 'components/LoadingTag';
import TranslatedLabel from 'components/TranslatedLabel';
import { Tag } from 'antd';

interface IBranchStatusTagProps {
	status: Nullable<EBranchStatus>;
}
const BranchStatusTag: FC<IBranchStatusTagProps> = memo(({ status }) => {
	const tBranchesTabPath = 'vendor_details.tabs.branches';

	const COLORS_CONFIG: Record<EBranchStatus, Property.Color> = {
		[EBranchStatus.PENDING]: 'orange',
		[EBranchStatus.APPROVED]: 'green',
		[EBranchStatus.BLOCKED]: 'red',
	};

	return status != null ? (
		<Tag
			style={DEFAULT_TAG_STYLES}
			color={COLORS_CONFIG[status]}
		>
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey={`${tBranchesTabPath}.status.${status}`}
			/>
		</Tag>
	) : (
		<LoadingTag />
	);
});

export default BranchStatusTag;
