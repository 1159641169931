import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { LocalTimeDate, useAuth, useCustomer, useNewHttpClient } from 'hooks';
import { CUSTOMER_API } from 'configs/api';
import { CARI_UNLIMITED_COUNTRIES_WHITE_LIST, DEFAULT_DESCRIPTIONS_PROPS, DEFAULT_MODAL_PROPS } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { CUSTOMER_EDIT_FORM_CONFIG } from './configs';
import { ECustomerStatus, ICustomer } from 'types/api';
import { ESupportedCountriesIsoTwoCodes, Nullable } from 'types/common';
import BasicForm from 'components/BasicForm';
import Spinner from 'components/Spinner';
import ErrorPage from 'pages/ErrorPage';
import { CustomerCariUnlimitedDetailsCard } from './CustomerCariUnlimitedDetails';
import { App, Button, Card, Col, Descriptions, Form, Modal, Row, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const DetailsTab: FC = () => {
	const {
		customerId,
		data: customerData,
		fetchHttpClient: { error, isLoading },
		fetchCustomerInformation,
	} = useCustomer();
	const { message } = App.useApp();
	const { hasPermission } = useAuth();

	const { t: tCustomers } = useTranslation('customers');
	const { t: tCommon } = useTranslation('common');

	const [form] = Form.useForm();
	const [customerIdEditing, setCustomerIdEditing] = useState<Nullable<number>>(null);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! http client
	const updateHttpClient = useNewHttpClient<ICustomer>();

	// ! useEffects
	useEffect(() => {
		fetchCustomerInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! handlers
	const onOpenCustomerEditModal = (data: ICustomer) => {
		setCustomerIdEditing(data.id);

		form.setFieldsValue({ ...data });
	};

	const updateCustomer = (id: number, payload: Pick<ICustomer, 'first_name' | 'last_name' | 'status'>) =>
		updateHttpClient.request({
			requestConfig: CUSTOMER_API.update(id, payload),
			successCallback: () => {
				message.success(tCustomers('customer_details.tabs.details.update_message', { id }), 5);

				fetchCustomerInformation();
			},
		});

	const onConfirmEditCustomer = () => {
		form.validateFields()
			.then(({ first_name, last_name, status }) => {
				form.resetFields();
				setCustomerIdEditing(null);
				updateCustomer(customerIdEditing!, { first_name, last_name, status });
			})
			.catch((info) => console.warn('Form Validate Failed:', info));
	};

	// ! render
	if (error) {
		return (
			<ErrorPage
				status={error.status}
				title={error.status}
				subTitle={error.data?.message}
			/>
		);
	}

	if (!customerId || !customerData || isLoading) {
		return <Spinner defaultAntdSpinner />;
	}

	const { first_name, last_name, status, email, country_code, phone_number, language, created_at } = customerData;

	const isCustomerEditBtnDisabled =
		status === ECustomerStatus.DELETED || !hasPermission(APP_PERMISSIONS.customer.update);

	const canShowCariUnlimitedDetails =
		hasPermission([APP_PERMISSIONS.cari_unlimited.view, APP_PERMISSIONS.cari_unlimited.subscription.view]) &&
		CARI_UNLIMITED_COUNTRIES_WHITE_LIST.includes(countryIsoTwoCode as ESupportedCountriesIsoTwoCodes);

	return (
		<>
			<Row
				key='row-1'
				gutter={[16, 16]}
			>
				<Col flex='1'>
					<Card key='main-info'>
						<Space
							direction='vertical'
							size='middle'
						>
							<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
								<Descriptions.Item label={tCustomers('shared.entity.name')}>
									{first_name} {last_name}
								</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.email')}>{email}</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.phone_number')}>
									({country_code}) {phone_number}
								</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.language')}>
									{language}
								</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.created_at')}>
									<LocalTimeDate date={created_at} />
								</Descriptions.Item>
							</Descriptions>

							<Button
								key='edit'
								icon={<EditOutlined />}
								disabled={isCustomerEditBtnDisabled} // If is read only customer or no permissions
								onClick={() => onOpenCustomerEditModal(customerData)}
							>
								{tCommon('action_buttons.edit')}
							</Button>
						</Space>
					</Card>
				</Col>

				{canShowCariUnlimitedDetails && (
					<Col flex='1'>
						<CustomerCariUnlimitedDetailsCard details={customerData.cari_unlimited_subscription} />
					</Col>
				)}
			</Row>

			{/* EDIT MODAL */}
			<Modal
				{...DEFAULT_MODAL_PROPS}
				open={!!customerIdEditing}
				title={tCustomers('customer_details.tabs.details.edit_modal_title', { id: customerIdEditing })}
				onCancel={() => setCustomerIdEditing(null)}
				onOk={() => onConfirmEditCustomer()}
			>
				<BasicForm
					form={form}
					name='customer_edit_form'
					config={CUSTOMER_EDIT_FORM_CONFIG}
				/>
			</Modal>
		</>
	);
};

export default DetailsTab;
