import { EStatus } from 'types/common';

export enum EScreenItemType {
	BOX = 'box',
	OPTION = 'option',
	BUTTON = 'button',
}
export interface ICustomerSupportFlow {
	id: string;
	slug: string;
	name: string;
	name_ar: string;
	order_status: string;
	status: EStatus;
	sort_order: number;

	actions: {
		can_be_edited: boolean;
	};
}

export interface ICustomerSupportFlowListItemResponse extends ICustomerSupportFlow {}

export interface IFlow {
	title: string;
	relations: IRelation[];
}

export interface IRelation {
	label: string; // text to the edge
	screen: IScreen;
}

export interface IScreen {
	title: string;
	items: IScreenItem[];
	[key: string]: unknown;
}

export interface IScreenItem {
	id?: string;
	path?: string;
	slug?: string;
	type: EScreenItemType;
	text?: string;
	height?: string;
	status?: EStatus;
	sub_text?: string;
	relations?: IRelation[];
}

export interface IUpdateFlowItemsStatusPayload {
	data: IFlowItemToUpdateInfo[];
}

export interface IFlowItemToUpdateInfo {
	path: string;
	slug: string;
	status: EStatus;
}
