import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomer, useNewHttpClient } from 'hooks';
import { processLogs } from './helper';
import { CUSTOMER_API } from 'configs/api';
import { IListResponse } from 'types/common';
import { ICariUnlimitedSubscriptionLogEntry } from './types';
import { Card, Empty, Timeline, TimelineItemProps } from 'antd';

const CariUnlimitedSubscriptionLogs: FC = () => {
	const { customerId } = useCustomer();

	const { t: tCariUnlimitedManager } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.cari_unlimited_manager',
	});

	// ! memos
	const orderListRequestConfig = useMemo(
		() => CUSTOMER_API.getSubscriptionLog(customerId, { per_page: '30' }),
		[customerId]
	);

	// ! states
	const [timeLineItems, setTimeLineItems] = useState<TimelineItemProps[]>([]);

	// ! http client
	const fetchHttpClient = useNewHttpClient<IListResponse<ICariUnlimitedSubscriptionLogEntry>>();

	// ! effects
	useEffect(() => {
		fetchHttpClient.request({
			requestConfig: orderListRequestConfig,
			successCallback: (response) => {
				setTimeLineItems(processLogs(response));
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderListRequestConfig]);

	// ! render
	return (
		<Card
			loading={fetchHttpClient.isLoading}
			title={tCariUnlimitedManager('subscription_logs')}
		>
			{!timeLineItems.length && <Empty />}

			<Timeline
				mode='left'
				items={timeLineItems}
			/>
		</Card>
	);
};

export default CariUnlimitedSubscriptionLogs;
