import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ILinkWrapperProps } from '../types';

const LinkWrapper: FC<ILinkWrapperProps> = ({ children, to, ...props }) => {
	return (
		<NavLink
			to={to}
			{...props}
		>
			{children}
		</NavLink>
	);
};

export default LinkWrapper;
