import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useAuth } from 'hooks';
import { DEFAULT_TABS_PROPS } from 'configs/common';
import { TRouteObject } from 'configs/routes';
import { ITabItem } from 'types/common';
import { ICustomTabsProps } from './types';
import { Tabs } from 'antd';

export const CustomTabs = <T,>({ routes, activeKey, onTabClick, onTabChange, ...props }: ICustomTabsProps<T>) => {
	const { hasPermission } = useAuth();

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	const transformRouteInITabItem = ({ path, handle: { label, icon, disabled } }: TRouteObject): ITabItem => ({
		key: path!,
		icon,
		label: <span>{label}</span>,
		disabled,
		style: DEFAULT_TABS_PROPS,
	});

	// ! memos
	const tabItems = useMemo<ITabItem[]>(
		() =>
			routes
				.filter(
					({ handle: { permissions, country_white_list } }) =>
						hasPermission(permissions) &&
						(!country_white_list || country_white_list?.includes(countryIsoTwoCode))
				)
				.map<ITabItem>(transformRouteInITabItem),

		[countryIsoTwoCode, hasPermission, routes]
	);

	// ! render
	return (
		<Tabs
			items={tabItems}
			destroyInactiveTabPane
			activeKey={activeKey}
			onTabClick={(activeKey) => onTabChange(activeKey as T)}
			{...props}
		/>
	);
};
