import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalTimeDate, useAuth, useCustomer } from 'hooks';
import { APP_PERMISSIONS } from 'configs/permissions';
import { EXPIRE_ON_DATE_FORMAT } from './configs';
import { ECariUnlimitedSubscriptionStatus } from 'types/api';
import Spinner from 'components/Spinner';
import { CustomerCariUnlimitedDetailsCard } from '../DetailsTab/CustomerCariUnlimitedDetails';
import CariUnlimitedSubscriptionCancellationModal from './CariUnlimitedSubscriptionCancellationModal';
import CariUnlimitedSubscriptionLogs from './CariUnlimitedSubscriptionLogs';
import styles from './CustomerCariUnlimitedManager.module.css';
import { Button, Card, Col, Flex, Row, Typography as T } from 'antd';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const CustomerCariUnlimitedManager: FC = () => {
	const { hasPermission } = useAuth();
	const { customerId, data: customerData, fetchHttpClient, fetchCustomerInformation } = useCustomer();

	const { t: tCariUnlimitedManager } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.cari_unlimited_manager',
	});

	// ! states
	const [openSubscriptionCancellationModal, setOpenSubscriptionCancellationModal] = useState(false);

	// ! memos
	const subscriptionData = useMemo(() => customerData?.cari_unlimited_subscription ?? null, [customerData]);

	const editSubscriptionBtnDisabled = useMemo(() => {
		const canManageSubscription = hasPermission(APP_PERMISSIONS.cari_unlimited.subscription.manage);

		if (!subscriptionData || !canManageSubscription) return true;

		const isCancelledOrExpiredStatus = [
			ECariUnlimitedSubscriptionStatus.CANCELLED,
			ECariUnlimitedSubscriptionStatus.EXPIRED,
		].includes(subscriptionData?.status);

		return isCancelledOrExpiredStatus || subscriptionData.pending_cancellation;
	}, [subscriptionData, hasPermission]);

	// ! effects
	useEffect(() => {
		fetchCustomerInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! render
	if (!customerData || fetchHttpClient.isLoading) {
		return <Spinner defaultAntdSpinner />;
	}

	return (
		<Flex
			vertical
			gap='large'
		>
			{/* MAIN INFO */}
			<Row
				key='cari_unlimited_subscription_manager'
				gutter={[24, 12]}
			>
				<Col flex='1'>
					<CustomerCariUnlimitedDetailsCard details={subscriptionData} />
				</Col>

				<Col flex='none'>
					<Card
						className='h-100'
						title={tCariUnlimitedManager('subscription_manage')}
					>
						<Flex
							vertical
							gap='middle'
						>
							<Button
								block
								type='primary'
								icon={<EditOutlined />}
								disabled={editSubscriptionBtnDisabled}
								onClick={() => setOpenSubscriptionCancellationModal(true)}
							>
								{tCariUnlimitedManager('edit_subscription_button')}
							</Button>

							{subscriptionData && subscriptionData.pending_cancellation && (
								<T.Text className={styles.expire_on_alert_label}>
									<Flex gap={4}>
										<ExclamationCircleOutlined />
										<span>{tCariUnlimitedManager('expire_on_alert')}</span>
										<LocalTimeDate
											format={EXPIRE_ON_DATE_FORMAT}
											date={subscriptionData.expires_on}
										/>
									</Flex>
								</T.Text>
							)}
						</Flex>

						{customerId && (
							<CariUnlimitedSubscriptionCancellationModal
								customerId={customerId}
								expiryDate={subscriptionData?.expires_on}
								open={openSubscriptionCancellationModal}
								onCloseModal={() => setOpenSubscriptionCancellationModal(false)}
							/>
						)}
					</Card>
				</Col>
			</Row>

			{/* LOGS */}
			<CariUnlimitedSubscriptionLogs />
		</Flex>
	);
};

export default CustomerCariUnlimitedManager;
