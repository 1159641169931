import { COUNTRIES_CODES_TO_ID_MAP } from 'configs/common';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';

export const getValidUrlCountryId = (accessibleCountries: number[]) => {
	if (!window.location || !accessibleCountries.length) return;

	// url: '/:countryIsoTwoCode/*'
	const urlCountryCode = window.location.pathname.split('/')[1]?.toLowerCase() as ESupportedCountriesIsoTwoCodes;
	const urlCountryId = COUNTRIES_CODES_TO_ID_MAP[urlCountryCode];

	return accessibleCountries.includes(urlCountryId) ? urlCountryId : accessibleCountries[0];
};
