import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { convertMenuIntoEntityMap } from './helper';
import {
	IMenu,
	IMenuAddCategory,
	IMenuAddProduct,
	IMenuDeleteItemsRequest,
	IMenuEntity,
	IMenuItemsSortRequest,
	IMenuPatchRequest,
	IMenuUpdateItemsStatusRequest,
	IToggleSnoozeProductPayload,
} from 'types/api';
import { Nullable } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { convertCoordinatesToKey } from 'pages/Vendors/VendorDetails/Tabs/Menu/MenuEditor/helper';
import { ETreeNodeType, ISortTarget } from '../../pages/Vendors/VendorDetails/Tabs/Menu/MenuEditor/types';
import { IMenuAPIConfigs, IMenuController, TOnSuccessCb } from '../../pages/Vendors/VendorDetails/Tabs/Menu/types';
import dayjs from 'dayjs';
import { App } from 'antd';

const useMenu = (api: IMenuAPIConfigs): IMenuController => {
	const { t: tMenuEditor } = useTranslation('menu-editor');

	const { message } = App.useApp();

	// ! states
	const [menu, setMenu] = useState<Nullable<IMenu>>(null);
	const [reserveMenu, setReserveMenu] = useState<Nullable<IMenu>>(null);
	const [sortTarget, setSortTarget] = useState<ISortTarget>({
		items: [],
		treeLevel: ETreeNodeType.CATEGORY,
		currentCoordinates: null,
	});

	const [menuEntityMap, setMenuEntityMap] = useState<Map<string, IMenuEntity>>(new Map());

	useEffect(() => {
		setMenuEntityMap((prev) => {
			const newMap = new Map(prev);

			if (!menu) {
				newMap.clear();
			} else {
				convertMenuIntoEntityMap(menu, newMap);
			}

			return newMap;
		});
	}, [menu]);

	// ! http clients
	const saveHttpClient = useNewHttpClient<IMenu>();
	const fetchHttpClient = useNewHttpClient<IMenu>();
	const addProductHttpClient = useNewHttpClient();
	const addCategoryHttpClient = useNewHttpClient();
	const handleTreeNodeStatusChangeHttpClient = useNewHttpClient();
	const deleteTreeNodeHttpClient = useNewHttpClient();
	const toggleSnoozeTreeNodeHttpClient = useNewHttpClient();
	const handleMenuPatchHttpClient = useNewHttpClient<IMenu>();

	// Sort Logic
	const cancelSort = () => {
		setReserveMenu(null);
	};
	const startSortMenu = (sortTarget: ISortTarget) => {
		if (menu) setReserveMenu(menu);

		setSortTarget(sortTarget);
	};

	const saveSortMenu = (menuEntities: IMenuEntity[], onSuccess = () => {}) => {
		if (!api.sortMenuEntityConfig) return;

		const mapNodeTypeToSortType: Record<ETreeNodeType, IMenuItemsSortRequest['sorting']> = {
			[ETreeNodeType.CATEGORY]: 'categories',
			[ETreeNodeType.SUBCATEGORY]: 'sub_categories',
			[ETreeNodeType.PRODUCT]: 'products',
		};

		const payload: IMenuItemsSortRequest = {
			sorting: mapNodeTypeToSortType[sortTarget.treeLevel],
			ids: menuEntities.map((entity) => entity.id),
			target: sortTarget.currentCoordinates || undefined,
		};

		saveHttpClient.request({
			requestConfig: api.sortMenuEntityConfig(payload),
			successCallback: (response) => {
				message.success(tMenuEditor('messages.sort.success'));
				setMenu(response);
				setReserveMenu(null);
				onSuccess();
			},
		});
	};

	const fetchMenu = (search?: string, signal?: AbortSignal) => {
		fetchHttpClient.request(
			{
				requestConfig: api.getMenuConfig(search),
				successCallback: (data) => {
					setMenu(data);
				},
			},
			signal
		);
	};

	const addProduct = (categoryId: number, data: IMenuAddProduct, onSuccess: TOnSuccessCb): void => {
		if (!api.addProductConfig) return;

		addProductHttpClient.request({
			requestConfig: api.addProductConfig(categoryId, data),
			successCallback: () => {
				onSuccess();
			},
		});
	};

	const addCategory = (data: IMenuAddCategory, onSuccess: TOnSuccessCb) => {
		if (!api.addCategoryConfig) return;

		addCategoryHttpClient.request({
			requestConfig: api.addCategoryConfig(data),
			successCallback: () => {
				onSuccess();
			},
		});
	};

	const handleTreeNodeStatusChange = (data: IMenuUpdateItemsStatusRequest) => {
		if (!api.updateTreeNodeStatusConfig) return;

		handleTreeNodeStatusChangeHttpClient.request({
			requestConfig: api.updateTreeNodeStatusConfig(data),
			successCallback: () => {
				setMenuEntityMap((prev) => {
					const newMap = new Map(prev);

					data.items.map(convertCoordinatesToKey).forEach((coordinate) => {
						const entity = newMap.get(coordinate);

						if (!entity) return;

						newMap.set(coordinate, {
							...entity,
							status: data.status,
						});
					});

					return newMap;
				});
			},
		});
	};

	const deleteTreeNode = (data: IMenuDeleteItemsRequest, onSuccess: TOnSuccessCb) => {
		if (!api.deleteTreeNodeConfig) return;

		deleteTreeNodeHttpClient.request({
			requestConfig: api.deleteTreeNodeConfig(data),
			successCallback: () => {
				onSuccess();
			},
		});
	};

	const toggleSnoozeTreeNode = (data: IToggleSnoozeProductPayload) => {
		if (!api.toggleProductSnoozeConfig) return;

		const { product_id, ...payload } = data;

		toggleSnoozeTreeNodeHttpClient.request({
			requestConfig: api.toggleProductSnoozeConfig(product_id as number, payload),
			successCallback: () => {
				setMenuEntityMap((prev) => {
					const newMap = new Map(prev);

					const entityCoordinate = convertCoordinatesToKey({
						category_id: data.category_id,
						sub_category_id: data.sub_category_id,
						product_id: data.product_id,
					});

					const entity = newMap.get(entityCoordinate);

					if (entity) {
						newMap.set(entityCoordinate, {
							...entity,

							snooze: {
								start: dayjs().format(),
								end: dayjs().add(data.number_of_minutes, 'minutes').format(),
								is_snoozed: !!data.number_of_minutes,
							},
						});
					}

					return newMap;
				});
			},
		});
	};

	const handleMenuPatch = (data: IMenuPatchRequest, onSuccess: TOnSuccessCb) => {
		if (!api.patchMenuConfig) return;

		handleMenuPatchHttpClient.request({
			requestConfig: api.patchMenuConfig(data),
			successCallback: (data) => {
				message.success(
					<TranslatedLabel
						nameSpace='menu-editor'
						i18nKey='messages.edit.success'
					/>,
					3
				);
				setMenu(data);
				onSuccess();
			},
		});
	};

	return {
		// states
		menu,
		menuEntityMap,
		setMenu,
		sortTarget,
		loading:
			saveHttpClient.isLoading ||
			fetchHttpClient.isLoading ||
			addProductHttpClient.isLoading ||
			addCategoryHttpClient.isLoading ||
			handleTreeNodeStatusChangeHttpClient.isLoading ||
			deleteTreeNodeHttpClient.isLoading ||
			toggleSnoozeTreeNodeHttpClient.isLoading ||
			handleMenuPatchHttpClient.isLoading,

		isSortingMenu: !!reserveMenu,

		// api
		cancelSort,
		startSortMenu,
		saveSortMenu,
		fetchMenu,
		fetchMenuError: fetchHttpClient.error,
		addProduct,
		addCategory,
		handleTreeNodeStatusChange,
		deleteTreeNode,
		handleMenuPatch,
		toggleSnoozeTreeNode,
	};
};

const MenuControllerContext = createContext<IMenuController>({} as IMenuController);

const useMenuController = () => {
	return useContext(MenuControllerContext);
};

export { MenuControllerContext, useMenu, useMenuController };
