import { EOnOffStatus } from 'types/common';
import { EDocumentStatus, IRecordTimestamps, IRequiredDocument } from '.';
import { EOrderPaymentMethods, EOrderStatus } from './orders';
import { ISupplier } from './suppliers';
import dayjs from 'dayjs';

// ! enums
export enum EDriverAccountStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	BLOCKED = 'blocked',
	REJECTED = 'rejected',
}

export enum EDriverCapacityStatus {
	FREE = 'free', // Indicates the driver is online and has no orders.
	PARTIAL_CAPACITY = 'partial_capacity', // Indicates the driver is online and has orders but not at max capacity.
	FULL_CAPACITY = 'full_capacity', // Indicates the driver is online and at max order capacity.
}

export enum EDriverCashLimitStatus {
	WITHIN_LIMIT = 'within_limit',
	REACHED_LIMIT = 'reached_limit',
}

export enum EDriverLogAction {
	ACCOUNT_STATUS_CHANGED = 'account-status-changed',
	GOES_ONLINE = 'goes-online',
	GOES_OFFLINE = 'goes-offline',
}

export enum EInternalDriverVehicleType {
	BICYCLE = 'bicycle',
	MOTORCYCLE = 'motorcycle',
	CAR = 'car',
	OTHER = 'other',
	NULL = 'null',
}

export enum EDriverLocationStatus {
	VALID_LOCATION = 'valid_location',
	NO_LOCATION = 'no_location',
}

export enum EDriverAssignableOrderTypes {
	ALL = 'all', // can receive any order regardless of payment status or type
	PAID = 'paid', // can only receive paid orders
}

// ! interfaces
export interface IDriverInfo {
	first_name: string;
	last_name: string;
	email: string;
	country_code: string;
	phone_number: string;
	avatar: string;
	rating: number;
	// cash
	cash_limit: number;
	cash_at_hand: number;
	reached_cash_limit: EDriverCashLimitStatus;
	// orders
	max_parallel_orders: number;
	assignable_order_type: EDriverAssignableOrderTypes;
}

export interface IDriverStatusInfo {
	account_status: EDriverAccountStatus;
	capacity_status: EDriverCapacityStatus;
	location_status: EDriverLocationStatus;
	cash_status: EDriverCashLimitStatus;
	current_status: EOnOffStatus;
}

interface IDriverLocationInfo {
	last_location_time: Date | string;
	last_location_coordinates: {
		lat: string;
		lng: string;
	};
}

interface IDriverAreaInfo {
	id: number;
	name: string;
}

export interface IDriver {
	id: number;
	info: IDriverInfo;
	supplier: ISupplier;
	status: IDriverStatusInfo;
	location?: IDriverLocationInfo;
	areas: IDriverAreaInfo[];
}

export interface IDriverListItemResponse {
	id: IDriver['id'];
	info: {
		first_name: IDriverInfo['first_name'];
		last_name: IDriverInfo['last_name'];
		country_code: IDriverInfo['country_code'];
		phone_number: IDriverInfo['phone_number'];
		email: IDriverInfo['email'];
		rating: IDriverInfo['rating'];
		cash_limit: IDriverInfo['cash_limit'];
		cash_at_hand: IDriverInfo['cash_at_hand'];
		reached_cash_limit: IDriverInfo['reached_cash_limit'];
	};
	status: {
		account_status: IDriverStatusInfo['account_status'];
		capacity_status: IDriverStatusInfo['capacity_status'];
		location_status: IDriverStatusInfo['location_status'];
		current_status: IDriverStatusInfo['current_status'];
	};
}

// * Orders
export interface IDriverOrder {
	id: IDriver['id'];
	info: {
		code: string;
		status: EOrderStatus;
	};
	customer: {
		id: number;
		name: string;
	};
	payment: {
		amount_payable: number;
		method: EOrderPaymentMethods;
		paid_at: string;
		currency_code: string;
	};
}

// * Bank Details
export interface IDriverBankDetails {
	account_number: string;
	holder_name: string;
	bank_name: string;
	bank_location: string;
	payment_email: string;
	bic_swift_code: string;
}

// * Vehicle Details
export interface IDriverVehicleDetails {
	vehicle_type_id: number;
	vehicle_type_name: string;
	brand: string;
	model_name: string;
	model_year: number;
	plate_number: string;
}

// * Last Location
export interface IDriverLastLocation {
	driver_id: number;
	lat: string;
	lng: string;
}

// * Documents
export interface IDriverDocumentListItemResponse extends IRecordTimestamps {
	id: number;
	document_file: string;
	status: EDocumentStatus;

	required_document: IRequiredDocument;
}

// * Logs
export interface IDriverLogItem {
	id: number;
	driver_id: number;
	action: EDriverLogAction;
	description: string;
	account_status: string;
	online_status: string;
	user_id: number;
	user_name: string;
	user_role: string;
	created_at: string;
}

// ! CRUD -----------
export interface IUpdateDriverPayload {
	email?: string;
	first_name?: string;
	last_name?: string;
	country_code?: string;
	phone_number?: string;
	supplier_id?: number;
	cash_limit?: number;
	cash_at_hand?: number;
	area_ids?: number[];
	// orders
	max_parallel_orders?: number;
	assignable_order_type?: EDriverAssignableOrderTypes;
}

export interface IUpdateDriverCashInfoPayload extends Pick<IUpdateDriverPayload, 'cash_limit' | 'cash_at_hand'> {}

export interface IUpdateDriverOrdersInfoPayload
	extends Pick<IUpdateDriverPayload, 'max_parallel_orders' | 'assignable_order_type'> {}

// * Bank details
export interface IUpdateDriverBankDetailsFields {
	account_number: string;
	holder_name: string;
	bank_name: string;
	bank_location: string;
	payment_email: string;
	bic_swift_code: string;
}

export interface IUpdateDriverBankDetailsConfigArgs {
	id: number;
	data: IUpdateDriverBankDetailsFields;
}

// * Reset Driver Password
export interface ISetNewDriverPassPayload {
	password: string;
	confirm_password: string;
}

export interface IDriverCitcData {
	identity_type_id: string;
	id_number: string;
	date_of_birth: string | dayjs.Dayjs;
	mobile: string;
	region_id: string;
	car_type_id: string;
	city_id: string;
	car_number: string;
	vehicle_sequence_number: string;
}

export interface IUpdateDriverCitcData {
	identity_type_id: string;
	date_of_birth: string | dayjs.Dayjs;
	mobile: string;
	region_id: string;
	car_type_id: string;
	city_id: string;
	car_number: string;
	vehicle_sequence_number: string;
}
