export const LIST_DEFAULT_PARAMS = {
	page: '1',
	per_page: '10',
};

export * from './areas';
export * from './auth';
export * from './banners';
export * from './bin-card-segments';
export * from './cari-prize-campaign';
export * from './cari-unlimited';
export * from './categories';
export * from './citc';
export * from './companies';
export * from './configurations';
export * from './countries';
export * from './customers';
export * from './drivers';
export * from './generalSettings';
export * from './invoices';
export * from './liveTracking';
export * from './logistics';
export * from './metrics';
export * from './offers-labels';
export * from './operations-users';
export * from './orders';
export * from './payments';
export * from './permissions';
export * from './promotions';
export * from './pushNotifications';
export * from './ratings';
export * from './refunds';
export * from './requiredDocuments';
export * from './roles';
export * from './socket';
export * from './stores';
export * from './suppliers';
export * from './supportPages';
export * from './vehicleTypes';
export * from './vendors';
export * from './vendorStaff';
export * from './verticals';
export * from './customerSupportRequests';
