import { IMenuEntity, IMenuTreeCoordinate } from 'types/api';
import { ETreeNodeType, MenuTreeNode } from './types';

export const LEVEL_FIELD_NAME: Record<ETreeNodeType, string> = {
	[ETreeNodeType.CATEGORY]: 'category_id',
	[ETreeNodeType.SUBCATEGORY]: 'sub_category_id',
	[ETreeNodeType.PRODUCT]: 'product_id',
};

export const transformToMenuTreeStructure = (
	elementNodeList: IMenuEntity[] = [],
	level: ETreeNodeType = ETreeNodeType.CATEGORY,
	parentCoordinates?: IMenuTreeCoordinate
): Record<string, MenuTreeNode> => {
	const result: Record<string, MenuTreeNode> = {};

	elementNodeList.forEach((elementNode, index) => {
		let currentCoordinate;
		if (parentCoordinates === undefined) {
			currentCoordinate = {} as IMenuTreeCoordinate;
		} else {
			currentCoordinate = JSON.parse(JSON.stringify(parentCoordinates));
		}

		currentCoordinate[LEVEL_FIELD_NAME[level]] = elementNode.id;

		const newNode: MenuTreeNode = {
			id: JSON.stringify(currentCoordinate),
			nodeType: level,
			nodeElem: elementNode,
			children: {
				...transformToMenuTreeStructure(
					elementNode.sub_categories,
					ETreeNodeType.SUBCATEGORY,
					currentCoordinate
				),
				...transformToMenuTreeStructure(elementNode.products, ETreeNodeType.PRODUCT, currentCoordinate),
			},
			currentCoordinate,
			sortOrder: index,
		};
		result[elementNode.id] = newNode;
	});

	return result;
};

export const convertCoordinatesToKey = (menuCoordinate: IMenuTreeCoordinate): string => JSON.stringify(menuCoordinate);
