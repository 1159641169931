import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { FC } from 'react';
import { useSelector } from 'store';
import { getMapCenterBySelectedCountry } from 'store/selectors';
import { MAP_LIBRARIES } from 'configs/common';
import { ICommonMapProps } from './types';

const CommonMap: FC<ICommonMapProps> = ({ googleMapsApiKey, mapId, children, ...mapProps }) => {
	// * map js api
	const { isLoaded: isMapLoaded, loadError } = useLoadScript({
		id: mapId,
		googleMapsApiKey,
		libraries: MAP_LIBRARIES,
	});

	// ! selectors
	const { mapCenterByCountry } = useSelector(getMapCenterBySelectedCountry);

	const mapStylesOptions: google.maps.MapOptions = {
		clickableIcons: false,
		minZoom: 11, //zoom out
		styles: [
			{
				featureType: 'poi',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
		],
	};

	// ! render
	const { center, ...restMapProps } = mapProps;
	return (
		<>
			{isMapLoaded && !loadError && (
				<GoogleMap
					center={center || mapCenterByCountry}
					options={mapStylesOptions}
					{...restMapProps}
				>
					{children}
				</GoogleMap>
			)}
		</>
	);
};

export default CommonMap;
