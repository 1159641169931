import { FC, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { getIsRTL, getSettingsSlice } from 'store/selectors';
import { setLanguage } from 'store/slices/settings';
import { useAuth, useLogos } from 'hooks';
import { getLanguageMenu } from './helper';
import { ESupportedLanguages } from 'types/common';
import { INavProps } from './types';
import { SelectAreasDrawer } from 'components/SelectAreasDrawer';
import TranslatedLabel from 'components/TranslatedLabel';
import styles from './Nav.module.css';
import { useNavMenuController } from './useNavMenuController';
import { ESidebarState, useSidebarState } from './useSidebarState';
import { Layout as AntLayout, Button, ConfigProvider, Flex, Menu } from 'antd';
import { LogoutOutlined, RightOutlined } from '@ant-design/icons';

const Logo: FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
	const { miniLogo, fullLogo } = useLogos();

	const logo = useMemo(() => (isCollapsed ? miniLogo : fullLogo), [fullLogo, isCollapsed, miniLogo]);

	return (
		<img
			alt='logo'
			src={logo}
			className={styles.app_logo}
		/>
	);
};

export const Nav: FC<INavProps> = memo(({ items = [] }) => {
	const { selectedNavItems, navItems } = useNavMenuController(items);
	const auth = useAuth();
	const isRTL: boolean = useSelector(getIsRTL);

	const { i18n } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { isCollapsed, setSidebarState } = useSidebarState();

	// ! selectors
	const { language: selectedAppLanguage } = useSelector(getSettingsSlice);

	// ! useEffects
	useEffect(() => {
		i18n.changeLanguage(selectedAppLanguage);
	}, [i18n, selectedAppLanguage]);

	// ! useMemos
	const shouldIconRotate = useMemo(() => (isRTL ? isCollapsed : !isCollapsed), [isCollapsed, isRTL]);

	// ! handlers
	const onLanguageSelectChange = (newChosenLanguage: string) => {
		if (selectedAppLanguage != null && selectedAppLanguage !== newChosenLanguage) {
			setTimeout(() => {
				dispatch(setLanguage(newChosenLanguage as ESupportedLanguages));
				i18n.changeLanguage(newChosenLanguage);
			}, 0);

			// if changes, reload page
			navigate(0);
		}
	};

	const handleCollapseToggle = () => setSidebarState(isCollapsed ? ESidebarState.EXPANDED : ESidebarState.COLLAPSED);

	// ! render
	return (
		<ConfigProvider
			theme={{
				components: {
					Menu: {
						itemBorderRadius: 14,
						subMenuItemBorderRadius: 14,
						subMenuItemBg: 'transparent',
						itemPaddingInline: 20,
					},
				},
			}}
		>
			<AntLayout className='h-100'>
				<AntLayout.Sider
					width={280}
					className={styles.sidebar}
					theme='light'
					collapsible
					collapsed={isCollapsed}
					trigger={null}
				>
					<Button
						size='large'
						shape='circle'
						className={`${styles.custom_slider_trigger} ${isRTL ? 'rtl' : 'ltr'} `}
						onClick={handleCollapseToggle}
					>
						<Flex
							align='center'
							justify='center'
						>
							<RightOutlined className={`anticon ${shouldIconRotate ? 'rotate-180' : ''}`} />
						</Flex>
					</Button>
					<Flex
						vertical
						className='h-100'
					>
						<div className={styles.logo_container}>
							<Logo isCollapsed={isCollapsed} />
						</div>

						<Menu
							mode='inline'
							theme='light'
							items={navItems}
							className={`${styles.top_menu} ${isRTL ? 'rtl' : 'ltr'}`}
							selectedKeys={selectedNavItems}
						/>

						<div className={styles.bottom_menu}>
							<Menu
								mode='inline'
								selectable={false}
								items={[
									getLanguageMenu(selectedAppLanguage, onLanguageSelectChange),
									{
										key: 'logout_cta',
										label: <TranslatedLabel i18nKey='header.user.sign_out' />,
										icon: <LogoutOutlined />,
										onClick: () => auth.signout(),
									},
								]}
							/>
						</div>
					</Flex>
					<SelectAreasDrawer />
				</AntLayout.Sider>
			</AntLayout>
		</ConfigProvider>
	);
});
