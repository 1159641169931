import { AxiosRequestConfig } from 'axios';
import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { ICreateCorrectedOrderPayload, IOrder, IUpdateGlobalStatusPayload, IUpdateOrdersStatusConfig } from 'types/api';

export const ORDERS_API = {
	list: (params: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/orders',
		method: 'GET',
		params,
	}),

	dispatcherBadges: (params: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/orders/dispatcher-badges',
		method: 'GET',
		params,
	}),

	dispatcherList: (params: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/orders/dispatcher',
		method: 'GET',
		params,
	}),

	// ! CRUD
	get: (id: number): AxiosRequestConfig => ({
		url: `operations/orders/${id}`,
		method: 'GET',
	}),

	// ! -----

	// ! order logs
	getOrderHistory: (id: number): THttpRequestConfig => ({
		url: `operations/orders/${id}/history`,
		method: 'GET',
	}),

	// ! status
	updateOrderGlobalStatus: (id: number, data: IUpdateGlobalStatusPayload): THttpRequestConfig => ({
		url: `operations/orders/${id}`,
		method: 'PATCH',
		data,
	}),
	updateOrderStatus: (id: number, data: IUpdateOrdersStatusConfig): THttpRequestConfig => ({
		url: `operations/orders/${id}/status`,
		method: 'PATCH',
		data,
	}),

	// ! assign/unassign orders to/from driver
	assignOrderToDriverById: (id: number, data: { driver_id: number }): THttpRequestConfig => ({
		url: `operations/orders/${id}/assign-driver`,
		method: 'PATCH',
		data,
	}),

	assignOrderToDriverByCode: (orderCode: string, data: { driver_id: number }): THttpRequestConfig => ({
		url: `operations/orders/${orderCode}/assign-driver`,
		method: 'PATCH',
		data,
	}),

	unassignOrderFromDriver: (id: number): THttpRequestConfig => ({
		url: `operations/orders/${id}/unassign-driver`,
		method: 'PATCH',
	}),

	clearDispute: (id: number, comment: string): THttpRequestConfig => ({
		url: `operations/orders/${id}/clear-dispute`,
		method: 'POST',
		data: { comment },
	}),

	retryZoneDelivery: (id: number): THttpRequestConfig => ({
		url: `operations/orders/${id}/retry-delivery`,
		method: 'POST',
	}),

	// ! payment
	getOrderPayment: (orderCode: string): THttpRequestConfig => ({
		url: `operations/orders/${orderCode}/payment-response`,
		method: 'GET',
	}),

	// ! invoice
	generateOrderInvoice: (id: number): THttpRequestConfig => ({
		url: `operations/orders/${id}/generate-invoice`,
		method: 'POST',
	}),

	// ! rebroadcast
	rebroadcastDriverNotifications: (countryId: number): THttpRequestConfig => ({
		url: 'operations/orders/rebroadcast',
		method: 'POST',
		data: {
			country_id: countryId,
		},
	}),

	// ! CITC
	getOrderCitc: (orderId: number): THttpRequestConfig => ({
		url: `operations/orders/${orderId}/citc`,
		method: 'GET',
	}),

	// ! Correction order
	correctionOrder: (data: ICreateCorrectedOrderPayload): THttpRequestConfig => ({
		url: '/operations/orders/correction',
		method: 'POST',
		data: data,
	}),

	// ! Customer Support Requests
	getCustomerSupportRequests: (id: IOrder['id']): THttpRequestConfig => ({
		url: `/operations/orders/${id}/customer-support-requests`,
		method: 'GET',
	}),
};
