import { THttpFunction } from 'utils/axiosInstance';
import { SUPPORT_PAGES_API } from 'configs/api';
import { IPageType } from 'types/api';
import { IListResponse } from 'types/common';
import { IServiceMethodFunc } from './types';

interface IPagesService {
	fetchPageTypes: IServiceMethodFunc<THttpFunction, IListResponse<IPageType>>;
}

const pagesService: IPagesService = {
	fetchPageTypes: (http: THttpFunction) => http(SUPPORT_PAGES_API.pageTypesList()),
};

export default pagesService;
