import { FC, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { TRangeValue } from 'types/common';
import { IListDatesFilterWrapperContext } from './types';

const ListDatesFilterWrapper: FC = () => {
	const filterDatesRef = useRef<TRangeValue>(null);

	const handleDateChange = (dates: TRangeValue) => {
		filterDatesRef.current = dates;
	};

	const outletData: IListDatesFilterWrapperContext = {
		filterDatesRef,
		handleDateChange,
	};

	return <Outlet context={outletData} />;
};

export default ListDatesFilterWrapper;
