import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { CARI_UNLIMITED_COUNTRIES_WHITE_LIST } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { CARI_UNLIMITED_TAB_ICONS } from './configs';
import { ECariUnlimitedTabsKeys } from './types';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import TranslatedLabel from 'components/TranslatedLabel';
import { FireOutlined } from '@ant-design/icons';

const CariUnlimitedManagementPageLayout = lazy(() => import('pages/CariUnlimited'));
// tabs
const DetailsTabContent = lazy(() => import('pages/CariUnlimited/Tabs/Details'));
const PlanListTabContent = lazy(() => import('pages/CariUnlimited/Tabs/Plans/PlanListTabContent'));
const PlanDetailsPage = lazy(() => import('pages/CariUnlimited/Tabs/Plans/PlanDetails'));
const AddPlanPage = lazy(() => import('pages/CariUnlimited/Tabs/Plans/AddPlanPage'));
const VendorsTabContent = lazy(() => import('./Tabs/Vendors/VendorsTabContent'));
const SupportPagesTabContent = lazy(() => import('./Tabs/SupportPages'));

export const CARI_UNLIMITED_TAB_ROUTES: Record<ECariUnlimitedTabsKeys, TRouteObject> = {
	[ECariUnlimitedTabsKeys.DETAILS]: {
		path: ECariUnlimitedTabsKeys.DETAILS,
		element: <DetailsTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='cari-unlimited'
					i18nKey={ECariUnlimitedTabsKeys.DETAILS}
					extra={{ keyPrefix: 'tabs' }}
				/>
			),
			permissions: [],
			showOnNav: false,
			icon: CARI_UNLIMITED_TAB_ICONS[ECariUnlimitedTabsKeys.DETAILS],
		},
	},
	[ECariUnlimitedTabsKeys.PLANS]: {
		path: ECariUnlimitedTabsKeys.PLANS,
		element: <ListParamsRouteWrapper key='cari-unlimited-plans' />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='cari-unlimited'
					i18nKey={ECariUnlimitedTabsKeys.PLANS}
					extra={{ keyPrefix: 'tabs' }}
				/>
			),
			permissions: [],
			showOnNav: false,
			icon: CARI_UNLIMITED_TAB_ICONS[ECariUnlimitedTabsKeys.PLANS],
		},
		children: [
			// list page (index)
			{
				index: true,
				element: <PlanListTabContent />,
				handle: {
					defaultListParams: LIST_DEFAULT_PARAMS,
				},
			},
			// plan page
			{
				path: ':planId',
				children: [
					// details
					{
						index: true,
						element: <PlanDetailsPage />,
					},
				],
			},
			// new plan page
			{
				path: 'new',
				element: <AddPlanPage />,
			},
		],
	},
	[ECariUnlimitedTabsKeys.VENDORS]: {
		path: ECariUnlimitedTabsKeys.VENDORS,
		element: <VendorsTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='cari-unlimited'
					i18nKey={ECariUnlimitedTabsKeys.VENDORS}
					extra={{ keyPrefix: 'tabs' }}
				/>
			),
			permissions: [],
			showOnNav: false,
			icon: CARI_UNLIMITED_TAB_ICONS[ECariUnlimitedTabsKeys.VENDORS],
		},
	},

	[ECariUnlimitedTabsKeys.SUPPORT_PAGES]: {
		path: ECariUnlimitedTabsKeys.SUPPORT_PAGES,
		element: <SupportPagesTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='cari-unlimited'
					i18nKey={ECariUnlimitedTabsKeys.SUPPORT_PAGES}
					extra={{ keyPrefix: 'tabs' }}
				/>
			),
			permissions: [],
			showOnNav: false,
			icon: CARI_UNLIMITED_TAB_ICONS[ECariUnlimitedTabsKeys.SUPPORT_PAGES],
		},
	},
};

export const CARI_UNLIMITED_DETAILS_TAB_ROUTES: TRouteObject[] = Object.values(CARI_UNLIMITED_TAB_ROUTES);

export const CARI_UNLIMITED_ROUTES: TRouteObject = {
	path: 'cari-unlimited',
	element: <CariUnlimitedManagementPageLayout />,
	handle: {
		icon: <FireOutlined />,
		translationKey: 'cari_unlimited',
		permissions: [APP_PERMISSIONS.cari_unlimited.manage],
		country_white_list: CARI_UNLIMITED_COUNTRIES_WHITE_LIST,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: (
				<Navigate
					to={ECariUnlimitedTabsKeys.DETAILS}
					replace
				/>
			),
		},

		...CARI_UNLIMITED_DETAILS_TAB_ROUTES,
	],
};
