import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import countriesSlice from './slices/countries';
import dispatcherSlice from './slices/dispatcher';
import integrationsSlice from './slices/integrations';
import pagesSlice from './slices/pages';
import permissionsSlice from './slices/permissions';
import settingsSlice from './slices/settings';

export const AUTH_LOGOUT_ACTION = 'auth/logout';

const combinedReducer = combineReducers({
	countries: countriesSlice,
	settings: settingsSlice,
	permissions: permissionsSlice,
	pages: pagesSlice,
	dispatcher: dispatcherSlice,
	integrations: integrationsSlice,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
	if (action.type === AUTH_LOGOUT_ACTION) {
		state = undefined;
	}
	return combinedReducer(state, action);
};

export default rootReducer;
