import { FC, memo } from 'react';
import { getAdditionalFormProps, renderHelper } from './helpers';
import { IBasicFormProps } from './types';
import { Form } from 'antd';

const BasicForm: FC<IBasicFormProps> = memo(
	({
		form,
		config,
		name,
		layout = 'vertical',
		defaultFormItemsLayout,
		extraFormItems,
		loading,
		onFinish,
		onValuesChange,
		id,
	}) => {
		const additionalFormProps = getAdditionalFormProps(defaultFormItemsLayout);

		// ! render
		return (
			<Form
				form={form}
				name={name}
				layout={layout}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
				id={id}
				{...additionalFormProps}
			>
				{config.map((configItem) => {
					const {
						key,
						label = '',
						extra,
						validationsRules = [],
						initialValue,
						tooltip,
						children = renderHelper(configItem, loading),
					} = configItem;

					return (
						<Form.Item
							key={key}
							name={key}
							label={label}
							extra={extra}
							rules={validationsRules}
							tooltip={tooltip}
							initialValue={initialValue}
						>
							{children}
						</Form.Item>
					);
				})}
				{extraFormItems}
			</Form>
		);
	}
);

export default BasicForm;
