import { FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { ECariUnlimitedTabsKeys } from './types';
import { CustomTabs } from 'components/CustomTabs';
import PageLayout from 'components/PageLayout';
import { CARI_UNLIMITED_DETAILS_TAB_ROUTES } from './routes.cari-unlimited';
import { PageHeader } from '@ant-design/pro-layout';

const CariUnlimitedManagementPageLayout: FC = () => {
	const navigate = useNavigate();
	const { t: tCariUnlimited } = useTranslation('cari-unlimited');

	const match = useMatch(ABSOLUTE_ROUTES.MANAGEMENT_CARI_UNLIMITED_TABS + '/*');

	// ! handlers
	const onTabChange = (activeKey: ECariUnlimitedTabsKeys) => navigate(activeKey);

	// ! render
	return (
		<PageLayout
			header={
				<>
					<PageHeader title={tCariUnlimited('page_title')} />

					<CustomTabs
						routes={CARI_UNLIMITED_DETAILS_TAB_ROUTES}
						activeKey={match?.params.tab}
						onTabChange={onTabChange}
					/>
				</>
			}
		>
			<Suspense>
				<Outlet />
			</Suspense>
		</PageLayout>
	);
};

export default CariUnlimitedManagementPageLayout;
