import { EChoiceOption, EStatus } from 'types/common';
import { IBinCardSegmentListItemResponse } from './bin-card-segments';
import { EPaymentMethods } from './payments';

// ! enums
export enum EBenefitType {
	FREE_DELIVERY = 'free_delivery',
}

export enum EPlanCycleType {
	MINUTES = 'minutes',
	DAYS = 'days',
	WEEKS = 'weeks',
	MONTHS = 'months',
	YEAR = 'year',
}

export enum EPlanTrialDurationType {
	MINUTES = 'minutes',
	DAYS = 'days',
}

export enum EBinSegmentDiscountType {
	NONE = 'none',
	AMOUNT = 'amount',
	PERCENTAGE = 'percentage',
}

// ! interfaces
export interface ICariUnlimitedBenefit {
	id: number;
	type: EBenefitType;
	name: string;
	name_ar?: string;
	description: string;
	description_ar?: string;
}

export interface ICariUnlimited {
	id: number;
	status: EStatus;
	benefits: ICariUnlimitedBenefit[];
}

// * Plans
export interface ICariUnlimitedPlan {
	id: number;
	name: string;
	name_ar: string;
	status: EStatus;
	price: number;
	sort_order: number;
	is_default: EChoiceOption;

	cycle_step: number;
	cycle_type: EPlanCycleType;

	trial_duration: number;
	trial_duration_type: EPlanTrialDurationType;

	badge_text: string;
	badge_text_ar: string;

	accepted_payment_methods: [EPaymentMethods.CREDIT_CARD];
	benefits: ICariUnlimitedBenefit[];

	associated_bin_segment_details: {
		bin_segments: Array<{
			id: IBinCardSegmentListItemResponse['id'];
			name: IBinCardSegmentListItemResponse['name'];
			name_ar: IBinCardSegmentListItemResponse['name_ar'];
		}>;
		discount_type: EBinSegmentDiscountType;
		discount_value: number;
	};
}

export interface ICariUnlimitedPlanListItemResponse {
	id: ICariUnlimitedPlan['id'];
	name: ICariUnlimitedPlan['name'];
	name_ar: ICariUnlimitedPlan['name_ar'];
	is_default: ICariUnlimitedPlan['is_default'];
	price: ICariUnlimitedPlan['price'];
	sort_order: ICariUnlimitedPlan['sort_order'];
	status: ICariUnlimitedPlan['status'];
	associated_bin_segment_details: ICariUnlimitedPlan['associated_bin_segment_details'];
}

// CRUD
export interface IPlanCreatePayload
	extends Pick<
		ICariUnlimitedPlan,
		| 'name'
		| 'name_ar'
		| 'status'
		| 'price'
		| 'sort_order'
		| 'cycle_step'
		| 'cycle_type'
		| 'trial_duration'
		| 'trial_duration_type'
		| 'badge_text'
		| 'badge_text_ar'
		| 'accepted_payment_methods'
	> {
	benefit_ids: number[];
}

export interface IPlanUpdatePayload
	extends Partial<Pick<IPlanCreatePayload, 'status' | 'sort_order' | 'benefit_ids'>> {}

// Vendor Association
export interface IChangeAssociatedVendorsPayload {
	vendor_ids: number[];
}

export interface IManageBinCardSegmentPayload {
	bin_segment_list: number[];
	discount_type: EBinSegmentDiscountType;
	discount_value: number;
}
