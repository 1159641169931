import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDispatcherOrderTimelineProps } from './types';
import styles from './DispatcherOrderTimeline.module.css';
import { Flex, Typography as T, Timeline, TimelineItemProps, Tooltip } from 'antd';

export const DispatcherOrderTimeline: FC<IDispatcherOrderTimelineProps> = memo(({ timeline }) => {
	const { t: tDispatcher } = useTranslation('dispatcher');

	// ! memos
	const indexOfLastNonInvalidDate = useMemo(() => {
		const index = timeline.findIndex((s) => !s.description);
		return index < 0 ? timeline.length - 1 : index - 1;
	}, [timeline]);

	const currentTimelineStep = useMemo(
		() => timeline[indexOfLastNonInvalidDate],
		[timeline, indexOfLastNonInvalidDate]
	);

	const tooltipTimelineItems = useMemo(() => {
		const currentTimelineStepIndex = indexOfLastNonInvalidDate;

		const items: TimelineItemProps[] = timeline.map((step, index) => {
			const isNextStep = index > currentTimelineStepIndex;
			const isCurrentStep = index === currentTimelineStepIndex;
			const iconColor = isNextStep ? 'var(--info-color)' : 'var(--ant-primary-color)';

			return {
				key: step.key,
				dot: step.icon,
				color: iconColor,
				pending: !step.description,
				children: (
					<Flex gap='small'>
						<T.Text className={isNextStep ? styles.grey_text : ''}>
							{tDispatcher(`order_card.timeline.${step.key}`)}
						</T.Text>
						{step.description && (
							<T.Text className={isCurrentStep ? styles.highlight_text : styles.grey_text}>
								{step.description}
							</T.Text>
						)}
					</Flex>
				),
			};
		});

		return items;
	}, [timeline, indexOfLastNonInvalidDate, tDispatcher]);

	// ! render
	if (!currentTimelineStep) return null;

	return (
		<Tooltip
			color='white'
			placement='left'
			title={
				<Timeline
					mode='left'
					items={tooltipTimelineItems}
					className={styles.tooltip_timeline_container}
				/>
			}
		>
			<Flex
				gap={4}
				vertical
				align='center'
				justify='center'
				className={styles.current_timeline_step}
			>
				{currentTimelineStep.icon}
				<T.Text>{tDispatcher(`order_card.timeline.${currentTimelineStep.key}`)}</T.Text>
				<T.Text className={styles.current_timeline_step_description}>{currentTimelineStep.description}</T.Text>
			</Flex>
		</Tooltip>
	);
});
